<div>
    <div class="card">
        <div class="card-header">
            <h3>{{'documentation.edit' | translate}}</h3>
        </div>
        <div class="card-footer widget-user-header bg-primary"
            style="display: flex; flex-direction: column; height: 100%;">
            <div class="widget-user-header bg-primary" style="flex: 1; display: flex; flex-wrap: wrap;">
                <div class="col-sm-6 mb-3 mb-md-0" style="flex: 1;">
                    <h3 class="widget-user-username" style="font-size: 25px; margin-top: 5px;">
                        {{item?.catModel}}
                    </h3>
                    <h5 class="widget-user-desc">
                        {{ entity.pubCode | translate }}: {{item?.codice}} -
                        {{"global.card-item.data" | translate}}: {{item?.catData | date : "dd/MM/YYYY"}} -
                        {{ entity.pubRevision | translate }}. {{item?.catRevisione}}
                    </h5>
                </div>
                <div class="col-sm-6" style="flex: 1;">
                    <div class="btn-container">
                        <pf-dropdown class="ext" #dropdownDoc hide-arrow
                            style="border: none; width: auto; margin-bottom: 24px;">
                            <div slot="button">
                                <a class="nav-link bg-info dropdown-toggle" data-toggle="dropdown"
                                    aria-expanded="false">
                                    <i class="fas fa-paperclip"></i> {{"global.card-item.documentazioneAllegata" |
                                    translate}}
                                </a>
                            </div>
                            <!-- <div class="menu menu-custom" style="width: 300px !important;" slot="menu">
                                <span *ngFor="let file of item?.allegati" class="d-flex p-2 justify-content-between">
                                    <span>{{ file.allNome }}</span>
                                    <span>
                                        <a (click)="downloadAttachment(language.algFile)" title="{{ 'ricambi.form.ricDownload' | translate }}" role="button" *ngFor="let language of file.tblAllegatiLingues">
                                            <i [ngClass]="handleIconLanguage(language.lin)" class="flag-icon mr-2"></i>
                                        </a>
                                    </span>
                                </span>
                                <span *ngIf="item?.allegati.length === 0" class="d-flex p-2 justify-content-between">
                                    <span><i class="fas fa-info-circle"></i> {{ 'global.utils.attachmentsNotFound' | translate }}</span>
                                </span>
                            </div> -->
                        </pf-dropdown>

                        <pf-dropdown hide-arrow #dropdown style="border: none; width: auto;">
                            <div slot="button">
                                <button type="button" class="btn btn-app bg-info dropdown-toggle float-right">
                                    <i class="fas fa-file"></i>PDF</button>
                            </div>
                            <div slot="menu">
                                <a role="button" (click)="downloadDocument()" class="dropdown-item">
                                    {{"global.card-item.manuale" | translate}}
                                </a>
                                <a class="dropdown-item" role="button" (click)="downloadRicambi()">
                                    {{"global.card-item.ricambi" | translate}}
                                </a>
                                <div class="dropdown-divider">
                                    {{"global.card-item.garanziaAttivata" | translate}}
                                </div>
                                <a class="dropdown-item" target="_blank">
                                    {{"global.card-item.schedaTecnica" | translate}}
                                </a>
                            </div>
                        </pf-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body" *ngIf="!isLoading">
            <div class="d-flex flex-column">
                <ng-container>
                    <app-info-item [tipoPubblicazioni]="item?.tipoPubblicazioni" [currentItem]="item" [item]="opt"
                        [service]="nextTag" [skipHourCheck]="true" *ngFor="let opt of options"
                        (noLanguages)="handleNoLanguages(opt)"></app-info-item>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-modal-update-hour *ngIf="item" [item]="item"></app-modal-update-hour>