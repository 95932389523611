import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContattiService extends BaseHttpGeneric<any, any> {

  constructor() {
    super()
    this.controller ="Contatti"
  }
}
