import { ModalService } from '@/_application/services/modal.service';
import { UiState } from '@/_application/store/ui/state';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { FrecuencyService } from '@modules/Coupons/service/frequency.service';
import { TagliandiService } from '@modules/Coupons/service/tagliandi.service';
import { handleIconLanguage } from '@modules/helpers/helpers';
import { TypeServiceView } from '@modules/home/data';
import { PublicationAvariableView, TipoPubblicazioni } from '@modules/publications/interface/publication';
import { ILanguage, LanguageService } from '@modules/publications/services/language.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent implements OnInit {
  @HostBinding('class') class = "info-box"
  @Input() item: TypeServiceView = null
  @Input() currentItem: PublicationAvariableView = null
  @Input() tipoPubblicazioni: TipoPubblicazioni[] = []
  @Input() service: string = ''
  @Input() matCode: string = '0'
  @Input() skipHourCheck: boolean = false
  @Output() noLanguages = new EventEmitter<void>();
  public languages: ILanguage[] = []
  languagesSub: Subscription
  public user: UserCurrent | null = null
  public ui: Observable<UiState>;

  constructor(
    private _langSvc: LanguageService,
    private _router: Router,
    private _modalSvc: ModalService,
    private _authSvc: AuthenticationService,
    private _renderer: Renderer2,
    private readonly _tagliandiSvc: TagliandiService,
    private _frecSvc: FrecuencyService
  ) {
    this.user = this._authSvc.currentUserValue
  }
  get hasLanguages(): boolean {
    return this.languages.length > 0;
  }

  ngOnInit(): void {
    this.validateLang();
    if (this.languages.length === 0) {
      this.noLanguages.emit();
    }
  }

  validateLang() {
    const rest = this.tipoPubblicazioni.filter(elm => elm.pubTipo === this.item.id)
    this.languagesSub = this._langSvc.currentLanguages$.subscribe((res) => {
      const data = res.map(elem => {
        const view = rest.find(el => el.linId === elem.codice)
        if (!view) return null
        const icon = handleIconLanguage(elem)
        return {
          ...elem,
          ...view,
          icon
        }
      })
      const normalized = data.filter(opt => opt)
      this.languages = normalized;
    });
  }
  goToAction(opt: ILanguage) {
    if (!this.skipHourCheck && this.checkIfHourIsUpdated()) {
      this._modalSvc.setShowModal(true, this.currentItem)
      return
    }
    
    if(this.currentItem.matricole) {     
      const codice = this.currentItem.matricole.matGuid;
      this._tagliandiSvc.getCurrentHour(codice).subscribe(
        (currentTime: any) => {
          console.log(currentTime);
          const params = {
            FreTime: currentTime,
            pageNumber: 0,
            pageSize: 100
          }
          if(!currentTime) delete params.FreTime
          this._frecSvc.getFrecuencies(params).subscribe(({data}) => {
            console.log(data);
            const listFrecuencies = data.find(x => x.freTime == currentTime)
            if(listFrecuencies) {
              if(listFrecuencies.serId && listFrecuencies.serId?.label) {
                localStorage.setItem('nextTagliandi', listFrecuencies.serId.label)
              }else {
                localStorage.setItem('nextTagliandi', this.service)
              }
            }else {
              localStorage.setItem('nextTagliandi', this.service)
            }
            switch (this.item.id) {
              case 1:
                this.getHtml(opt.pubId, this.matCode, opt.linId)
                break;
              case 2:
                this.goToMaintenance(opt.pubId, this.currentItem.catID, this.matCode, opt.linId)
                break;
              case 3:
                
                this.goToRicambi(opt.pubId, this.matCode, opt.linId)
                break;
              default:
                this.getHtml(opt.pubId, this.matCode, opt.linId)
                break;
            }
          })
        }
      )
    }else {
      switch (this.item.id) {
        case 1:
          this.getHtml(opt.pubId, this.matCode, opt.linId)
          break;
        case 2:
          this.goToMaintenance(opt.pubId, this.currentItem.catID, this.matCode, opt.linId)
          break;
        case 3:
          
          this.goToRicambi(opt.pubId, this.matCode, opt.linId)
          break;
        default:
          this.getHtml(opt.pubId, this.matCode, opt.linId)
          break;
      }
  
      console.log(this.service);
      localStorage.setItem('nextTagliandi', this.service)
    }

  }

  checkIfHourIsUpdated(): boolean {
    if (this.currentItem) {
      const lastDate = new Date(this.currentItem.garDataPrimoAvviamento);
      const currentDate = new Date();
      const diffInMilliseconds = currentDate.getTime() - lastDate.getTime();
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
      return diffInHours > 24;
    }
    return false;
  }

  public goToMaintenance(codice: number, catId: number, matCode: string, linID: number = 1) {
    this._router.navigate(['/manutenzione', codice, catId, matCode], {
      queryParams: {
        linID
      }
    })
  }

  goToRicambi(codice: number,matCode: string, linID: number = 1) {
    try {
      this._router.navigate(['/ricambi', codice, matCode], { 
        queryParams: { linID } 
      })
    } catch (error) {
      console.log(error)
    }
  }
  getHtml(codice: number, matCode: string, linID: number = 1) {
  //   // this._router.navigate(['/manuali', codice, matCode])
  this._router.navigate(['/manuali', codice, matCode], { 
    queryParams: { linID } 
  }).then(() => {
    this._renderer.removeClass(document.querySelector('app-root'), 'sidebar-open');
    this._renderer.addClass(document.querySelector('app-root'), 'sidebar-collapse');
  });
  }
}
