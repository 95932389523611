import { ValueNav } from '@/_application/interfaces/select';
import { Component } from '@angular/core';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  constructor( ) {  }

  optionsNaV = [
      {
          label: 'global.utils.informazioniGenerali',
          value: {
              opt: ''
          }
      },
      {
          label: 'global.utils.cambiarePassword',
          value: {
              opt: 'changePassword',
          }
      },
  ]
  public nav: ValueNav = {
      opt: ''
  };

  handleSelectedNav(nav: ValueNav) {
    this.nav = nav
  }
}
