import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService implements OnDestroy {
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  isDownloading$: Observable<boolean>;
  isDownloadingSubject: BehaviorSubject<boolean>;

  private unsubscribe: Subscription[] = [];

  constructor() {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.isDownloadingSubject = new BehaviorSubject<boolean>(false);
    this.isDownloading$ = this.isDownloadingSubject.asObservable();

  }

  load() {
    this.isLoadingSubject.next(true);
  }

  hide() {
    this.isLoadingSubject.next(false);
  }

  loadDownloading() {
    this.isDownloadingSubject.next(true);
  }

  hideDownloading() {
    this.isDownloadingSubject.next(false);
}
  ngOnDestroy() {
      this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
