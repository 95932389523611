import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { ILanguage, LanguageView } from '../interfaces/language.interface';
import { ResponseApi } from '@/_application/interfaces/response';
import { BehaviorSubject, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends BaseHttpGeneric<any, ILanguage>  {

  clearSelectEvent = new BehaviorSubject<boolean>(false);
  clearSelectEvent$ = this.clearSelectEvent.asObservable();

  constructor() {
    super()
    this.controller = 'Lingue'
  }

  getClearSelectedEvent() {
    return this.clearSelectEvent.value
  }

  setClearSelectedValue(value: boolean) {
    this.clearSelectEvent.next(value)
  }

  getWithName(name:string){
    this._loadingSvc.load()
    const url = this.handleUrl()
    return this.http.get<ResponseApi<LanguageView[]>>(
      `${url}/GetWithName`, {
        params:{
          name
        }
      }
  ).pipe(
      finalize(() => {
          this._loadingSvc.hide()
      })
  )
  }
}
