export const ConfigTableContatti = {
    codigo: 'userGuid',
    route: 'contatti',
    module: 'contatti',
    view: 'form',
    rows: [
        {
            fields: 'nome',
            header: 'nome',
            width: '200px'
        },
        {
            fields: 'cognome',
            header: 'cognome',
            width: '200px'
        },
        {
            fields: 'email',
            header: 'email',
            width: '250px'
        }
    ]
}