import { HelperSelect } from '@/_application/helpers/helper-select';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AgentsService } from '@modules/agents/services/agents.service';
import { OptionsSelect } from '../../../interfaces/select';
import { finalize } from 'rxjs';
import { selectEntity } from '@/_application/config/genericEntity';

@Component({
  selector: 'app-select-agenti',
  templateUrl: './select-agenti.component.html',
  styleUrls: ['./select-agenti.component.scss']
})
export class SelectAgentiComponent extends HelperSelect implements OnChanges {
  @Input() filiale: OptionsSelect | null = null
  pubEntity = selectEntity

  constructor(private _patternSvc: AgentsService) {
    super()
  }
  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }, this.query).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(resp => {
        const { data } = resp

        const options = data.map((elem): OptionsSelect => {
          const { codice, ageRagioneSociale } = elem
          return {
            label: ageRagioneSociale,
            value: codice,
          }
        })

        this.rows = options
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    const { filiale } = changes
    if (!filiale?.currentValue) {
      this.query = null
      this.handleData('')
      return
    }
    const value = filiale.currentValue.value
    const query = {
      filId: value
    }
    this.query = query
    this.handleData('')
  }
}
