import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ResponseApi } from '@/_application/interfaces/response';
import { General, GeneralView } from '../interface/general';

@Injectable({
  providedIn: 'root'
})
export class GeneralService extends BaseHttpGeneric<General, GeneralView> {

  constructor() {
    super()
    this.controller = 'Generali'
  }
}
