import {ExportFormat} from '@/_application/config/config';
import {ResponseApi, SortType} from '@/_application/interfaces/response';
import {SearchApi} from '@/_application/interfaces/search';
import {LoadingService} from '@/_application/services/loading.service';
import {HttpClient} from '@angular/common/http';
import {inject} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable, finalize} from 'rxjs';

export abstract class BaseHttpGeneric<TEntity, TResponse> {
    public controller = '';
    public http: HttpClient = inject(HttpClient);
    public _loadingSvc: LoadingService = inject(LoadingService);

    getAllAsyncWhitQuery(
        params: SearchApi,
        query?: any
    ): Observable<ResponseApi<TResponse[]>> {
        const {
            currentPage: pageNumber,
            pageSize,
            search,
            loading = true,
            auth,
            orderBy = '',
            orderType = 0,
            queryColumns
        } = params;
        const url = this.handleUrl(auth);
        loading && this._loadingSvc.load();

        const queryParams = {
            ...(search ? {search} : {}),
            ...(pageNumber ? {pageNumber} : {}),
            ...(pageSize ? {pageSize} : {}),
            orderBy: orderBy,
            orderType,
            ...query
        };

        if (queryColumns && queryColumns.length > 0) {
            queryColumns.forEach((column) => {
                const {field, value} = column;
                queryParams[field] = value;
            });
        }

        return this.http
            .get<ResponseApi<TResponse[]>>(`${url}/`, {
                params: queryParams
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }

    getAllUnionAsyncWhitQuery(
        params: SearchApi,
        query?: any
    ): Observable<ResponseApi<TResponse[]>> {
        const {
            currentPage: pageNumber,
            pageSize,
            search,
            loading = true,
            auth,
            orderBy = '',
            orderType = 0,
            queryColumns
        } = params;
        const url = this.handleUrl(auth);
        loading && this._loadingSvc.load();

        const queryParams = {
            ...(search ? {search} : {}),
            ...(pageNumber ? {pageNumber} : {}),
            ...(pageSize ? {pageSize} : {}),
            orderBy: orderBy,
            orderType,
            ...query
        };

        if (queryColumns && queryColumns.length > 0) {
            queryColumns.forEach((column) => {
                const {field, value} = column;
                queryParams[field] = value;
            });
        }

        return this.http
            .get<ResponseApi<TResponse[]>>(`${url}/all-union`, {
                params: queryParams
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }

    getAllMatricoleAsyncWhitQuery(
        params: SearchApi,
        query?: any,
        userGuid?
    ): Observable<ResponseApi<TResponse[]>> {
        const {
            currentPage: pageNumber,
            pageSize,
            search,
            loading = true,
            auth,
            orderBy = '',
            orderType = 0,
            queryColumns
        } = params;
        const url = this.handleUrl(auth);
        loading && this._loadingSvc.load();

        const queryParams = {
            ...(search ? {search} : {}),
            ...(pageNumber ? {pageNumber} : {}),
            ...(pageSize ? {pageSize} : {}),
            orderBy: orderBy,
            orderType,
            ...query,
            ...(userGuid ? {userGuid} : {})
        };

        if (queryColumns && queryColumns.length > 0) {
            queryColumns.forEach((column) => {
                const {field, value} = column;
                queryParams[field] = value;
            });
        }

        return this.http
            .get<ResponseApi<TResponse[]>>(`${url}/matricole`, {
                params: queryParams
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }
    getAllAsyncWithQueryMat(
        params: SearchApi,
        query?: any
    ): Observable<ResponseApi<TResponse[]>> {
        const {
            currentPage: pageNumber,
            pageSize,
            search,
            loading = true,
            auth,
            orderBy = '',
            orderType = 0,
            queryColumns
        } = params;
        const url = this.handleUrl(auth);
        loading && this._loadingSvc.load();

        const queryParams = {
            ...(search ? {search} : {}),
            ...(pageNumber ? {pageNumber} : {}),
            ...(pageSize ? {pageSize} : {}),
            orderBy: orderBy,
            orderType,
            ...query
        };

        if (queryColumns && queryColumns.length > 0) {
            queryColumns.forEach((column) => {
                const {field, value} = column;
                queryParams[field] = value;
            });
        }

        return this.http
            .get<ResponseApi<TResponse[]>>(`${url}/Front`, {
                params: queryParams
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }

    getAllTable(params: SearchApi, query?: any): Observable<TResponse> {
        const {currentPage, pageSize, search, loading = true} = params;
        loading && this._loadingSvc.load();

        let url = this.handleUrl();

        const httpParams = {
            ...query,
            ...(search && {search})
        };

        return this.http
            .get<TResponse>(url, {
                params: httpParams
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }

    getAllTableDoc(
        params: SearchApi,
        query?: any
    ): Observable<ResponseApi<TResponse[]>> {
        const {
            currentPage: pageNumber,
            pageSize,
            search,
            loading = true,
            auth,
            orderBy = '',
            orderType = 0,
            queryColumns
        } = params;
        const url = this.handleUrl(auth);
        loading && this._loadingSvc.load();

        const queryParams = {
            ...(search ? {search} : {}),
            ...(pageNumber ? {pageNumber} : {}),
            ...(pageSize ? {pageSize} : {}),
            orderBy: orderBy,
            orderType,
            ...query
        };

        if (queryColumns && queryColumns.length > 0) {
            queryColumns.forEach((column) => {
                const {field, value} = column;
                queryParams[field] = value;
            });
        }

        return this.http
            .get<ResponseApi<TResponse[]>>(`${url}/all`, {
                params: queryParams
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }

    getHtml(name: string): Observable<any> {
        this._loadingSvc.load();
        const url = this.handleUrl();

        const queryParams = {
            name: name
        };

        return this.http
            .get(`${url}/`, {
                params: queryParams,
                responseType: 'text'
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }
    getTermsAndConditionsDoc(name: string): Observable<any> {
        this._loadingSvc.load();
        const url = this.handleUrl();

        const queryParams = {
            name: name
        };

        return this.http
            .get(`${url}/termsAndConditionsDoc`, {
                params: queryParams,
                responseType: 'text'
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }
    downloadHtml(name: string): Observable<any> {
        this._loadingSvc.loadDownloading();
        const url = this.handleUrl();

        const queryParams = {
            name
        };

        return this.http
            .get(`${url}/download`, {
                params: queryParams,
                responseType: 'blob'
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hideDownloading();
                })
            );
    }
    getAllAsyncWhitParams(
        params: SearchApi,
        query?: any
    ): Observable<TResponse> {
        const url = this.handleUrl();
        const {currentPage, pageSize, search, loading = true} = params;
        loading && this._loadingSvc.load();

        return this.http
            .get<TResponse>(`${url}/${currentPage}/${pageSize}`, {
                params: {
                    search,
                    ...query
                }
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }
    exportExcelOrPdf(
        format: ExportFormat,
        objectDb: string,
        queryColumns?: any,
        orderType?: SortType,
        orderBy: string = '',
        query?: any,
        auth = false
    ): Observable<Blob> {
        this._loadingSvc.load();
        const url = this.handleUrl(auth);

        const queryParams = {
            format,
            data: objectDb,
            orderType: orderType || 0,
            orderBy
        };

        if (queryColumns && queryColumns.length > 0) {
            queryColumns.forEach((column) => {
                const {field, value} = column;
                queryParams[field] = value;
            });
        }

        if (query) {
            queryParams['userGuid'] = query.userGuid;
        }

        return this.http
            .get(`${url}/ExportExcelOrPdf`, {
                responseType: 'blob',
                params: queryParams
            })
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }
    deleteAsync(id: number, atuh = false): Observable<ResponseApi<string>> {
        const url = this.handleUrl();
        return this.http.delete<ResponseApi<string>>(`${url}/${id}`);
    }
    validateEmptyData(data) {
        for (let prop in data) {
            if (data.hasOwnProperty(prop) && typeof data[prop] === 'string') {
                // @ts-ignore
                data[prop] = (data[prop] as string).trim();
            }
        }
        return data;
    }
    saveAsync(data: TEntity, auth = false): Observable<ResponseApi<TEntity>> {
        const url = this.handleUrl(auth);
        const normalizedData = this.validateEmptyData(data);
        return this.http.post<ResponseApi<TEntity>>(`${url}/`, normalizedData);
    }

    sendReport(data: any, auth = false): Observable<ResponseApi<string>> {
        this._loadingSvc.load();
        const url = this.handleUrl(auth);
        return this.http
            .post<ResponseApi<string>>(`${url}/Send-report`, data)
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }

    saveFilesAsync(
        data: any,
        auth = false,
        customUrl: string,
        folderToSave: string = 'Languages'
    ): Observable<ResponseApi<TEntity>> {
        const url = this.handleUrl(auth);
        const formData = new FormData();
        formData.append('file', data);
        return this.http.post<any>(
            `${url}/${customUrl}?folder=${folderToSave}`,
            formData
        );
    }
    updateAsync(
        data: TEntity,
        id: number,
        auth = false
    ): Observable<ResponseApi<TEntity>> {
        const url = this.handleUrl(auth);
        const normalizedData = this.validateEmptyData(data);
        return this.http.put<ResponseApi<TEntity>>(
            `${url}/${id}`,
            normalizedData
        );
    }
    getByIdAsync(id: number, auth = false): Observable<ResponseApi<TEntity>> {
        this._loadingSvc.load();
        const url = this.handleUrl(auth);
        return this.http.get<ResponseApi<TEntity>>(`${url}/${id}`).pipe(
            finalize(() => {
                this._loadingSvc.hide();
            })
        );
    }
    handleUrl(auth = false) {
        const {server, local, production} = environment;
        if (production) {
            const {authenticate, apiUrl} = server;
            const url = auth ? authenticate : apiUrl;
            return `${url}/${this.controller}`;
        }
        const {authenticate, apiUrl} = local;
        const url = auth ? authenticate : apiUrl;
        return `${url}/${this.controller}`;
    }
    getAllRecordDataAsync(
        id: number,
        auth = false
    ): Observable<ResponseApi<TEntity>> {
        const url = this.handleUrl();
        this._loadingSvc.load();

        return this.http
            .get<ResponseApi<TEntity>>(`${url}/all-record-data/${id}`)
            .pipe(
                finalize(() => {
                    this._loadingSvc.hide();
                })
            );
    }
}
