<label for="">{{ label ? label : 'clienti.form.cliLogo' | translate }}</label>
<div class="wrapper">
    <img *ngIf="!showDefaultImg" [src]="handleImage(imageUrl)" alt="" class="show-image" />
    <img *ngIf="showDefaultImg" [src]="handleDefaultImage(defaultImg)" alt="" class="show-image" />
    <label *ngIf="user.current.role.canWrite">
      <i class="fa fa-pen icon-sm text-muted"></i>
      <input
        type="file"
        name="photo"
        accept="image/*"
        class="photo"
        (change)="handleChangeFile($event)"
        id="photo"
      />
    </label>
    <span class="btn-closed" (click)="handleDeleteImg()" *ngIf="imageUrl && user.current.role.canWrite">
      <i class="fa fa-times icon-xs text-muted"></i>
    </span>
  <span class="form-text text-muted">{{ 'global.utils.fileType' | translate }}: png, jpg, jpeg.</span>
  </div>
  <div class="wrapper" *ngIf="showError">
    <div class="message font-normal text-base text-error">
      {{ message }}
    </div>
  </div>
