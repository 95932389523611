import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { NationsService } from '@modules/nations/services/nations.service';
import { LanguageService } from '@modules/publications/services/language.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-default-lang',
  templateUrl: './select-default-lang.component.html',
  styleUrls: ['./select-default-lang.component.scss']
})
export class SelectDefaultLangComponent extends HelperSelect {
  @Input() showError: boolean = false;
  @Input() control: any;
  constructor(private _patternSvc: LanguageService) {
    super();
  }

  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getLanguages().pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp
      const options = data.map((elem): OptionsSelect => {
        const { codice, linNome } = elem
        return {
          label: linNome,
          value: codice
        }
      })
      this.rows = options
    })
  }

}
