import { Injectable } from '@angular/core';
import moment from 'moment';
import { ExportFormat } from '../config/config';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private translate: TranslateService
  ) { }

  generatePdf(data: any, name: string, format: ExportFormat) {
    const formattedDate = moment().format('YYYYMMDD_HHmmss');
    const file  = typeFormat[format]
    const blob = new Blob([data], { type: file.type });
    saveAs(blob, `${this.translate.instant(name)}_${formattedDate}.${file.ext}`);    
  }

}
export const typeFormat = {
  1: {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ext: 'xlsx',
  },
  2: {
    type: 'application/pdf;charset=utf-8',
    ext: 'pdf',
  }
}
