import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private showModal = new BehaviorSubject<boolean>(false);
  showModal$ = this.showModal.asObservable();

  private current = new BehaviorSubject<any>(null);
  current$ = this.current.asObservable();

  private currentModalId = new BehaviorSubject<string | null>(null);
  currentModalId$ = this.currentModalId.asObservable();

  constructor() { }

  setShowModal(value: boolean, current?: any, id?: string) {
    this.showModal.next(value);
    this.current.next(current);
    if (id) {
      this.currentModalId.next(id);
    } else {
      this.currentModalId.next(null);
    }
  }

  getShowModal() {
    return this.showModal.value;
  }

  get currentValue() {
    return this.current.value;
  }

  getCurrentModalId() {
    return this.currentModalId.value;
  }
}
