import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HtmlService extends BaseHttpGeneric<any, any>  {

  constructor() {
    super()
    this.controller = 'Html'
  }

  getHtmlUrl(normalizedPath: string) {
    let url = ''

    if (environment.production) {
      url = environment.server.apiUrl + '/Html?name=' + normalizedPath;
    } else {
      url = environment.local.apiUrl + '/Html?name=' + normalizedPath;
    }

    return url
  }

}
