import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core"
import { OptionsSelect } from "../interfaces/select"
import { dataDefaultSelect } from "../config/select"
import { statusCombox } from "../components/select-generic/select-generic.component"
import { UserCurrent } from "@modules/authentication/interfaces/user"
import { AuthenticationService } from "@modules/authentication/services/authentication.service"

@Component({
    template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class HelperSelect implements OnInit {
    public rows: OptionsSelect[] = []
    public default = dataDefaultSelect
    public loading = false
    public query!: any
    public status!: statusCombox
    @Output() changeData = new EventEmitter()
    @Input() code: OptionsSelect | null = null
    @Input() disabled: boolean = false
    @Input() readonly?: boolean = false
    @Input() required: boolean = false
    @Input() codeView: string = ""
    public user: UserCurrent | null = null

    constructor() {
        this.user = this._authSvc.currentUserValue
    }
    private readonly _authSvc: AuthenticationService = inject(AuthenticationService);

    ngOnInit(): void {
        if (!this.disabled) this.handleData("")
        this.disabled = !this.user.current.role.canWrite
    }



    handleChange(value: any) {
        this.changeData.emit(value)
    }
    protected abstract handleData(search: string, query?: any): void;

}