import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private appService: AuthenticationService, private router: Router, private readonly translate: TranslateService,) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const empty = Object.entries(this.appService.currentUserValue).length === 0
        if (empty || !this.appService.currentUserValue.current) {
            this.router.navigate(['/auth/login']);
            return of(false);
        }

        if (this.appService.currentUserValue.load) return of(true);

        return this.appService.verifyCredentials()
            .pipe(map((response) => {
                const value: string = this.translate.instant('sidebar.menu.Amministrazione')
                this.appService.createCookieDetails(response.data)
                this.appService.changeStatus('verifed')
                return true;
            }),
                catchError(() => {
                    this.appService.changeStatus('verifed')
                    this.appService.deaAthorizeToken()
                    this.router.navigate(['/auth/login']);
                    return of(false);
                })
            )

    }



    async getProfile() {
        const empty = Object.entries(this.appService.currentUserValue).length === 0
        if (!empty && !!this.appService.currentUserValue.current) {
            return true;
        }
        this.router.navigate(['/auth/login']);
        return false;

    }
}
