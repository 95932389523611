import { Metadata } from '@/_application/interfaces/response';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-generic-pagination',
  templateUrl: './generic-pagination.component.html',
  styleUrls: ['./generic-pagination.component.scss']
})
export class GenericPaginationComponent {
  @Input() meta!: Metadata
  @Output() page = new EventEmitter<Metadata>()
  public linkPages: number[] = []

 
  handleLinkPages() {
    const linkPages: number[] = []
    const { hasNextPage, hasPreviousPage, currentPage, totalPages } = this.meta
    if (hasPreviousPage) {
      const limit = hasNextPage ? 2 : 4
      for (let index = currentPage - limit; index < currentPage; index++) {
        if (index > 0) {
          linkPages.push(index)
        }
      }
    }
    linkPages.push(currentPage)
    if (hasNextPage) {
      const limit = hasPreviousPage ? linkPages.length === 2 ? 3 : 2 : 4
      for (let index = currentPage + 1; index <= currentPage + limit; index++) {
        if (index <= totalPages) {
          linkPages.push(index)
        }

      }
    }
    return linkPages
  }

  changePageLink(page: number) {
    const meta: Metadata = {
      ...this.meta,
      currentPage: page
    }
    this.page.emit(meta)
  }

  next() {
    const { currentPage, hasNextPage } = this.meta
    if (!hasNextPage) return
    const meta: Metadata = {
      ...this.meta,
      currentPage: currentPage + 1
    }
    this.page.emit(meta)

  }
  
  previus() {
    const { currentPage, hasPreviousPage } = this.meta
    if (!hasPreviousPage) return
    const meta: Metadata = {
      ...this.meta,
      currentPage: currentPage - 1
    }
    this.page.emit(meta)
  }

}
