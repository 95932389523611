import { FormGeneric } from '@/_application/helpers/generic/form-generic';
import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { UserService } from '@modules/users/services/user.service';
import { UserEntity } from '@pages/config/entity';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends FormGeneric {

  constructor(private readonly _patternSvc: UserService,
    private readonly _appSvc: AuthenticationService
  ) {
    super();
    this.entity = { ...UserEntity, route: '/' }
    this.initConfigExt = false
    this.open = false
  }
  protected initForm(): Observable<boolean | any> {
    this.form = this._fb.group({
      password: [null, [Validators.required]],
      cPassword: [null, [Validators.required]],
    })
    this.form.addValidators(this.validations.validatePassword)
    return of(true);
  }
  protected deleted(): void {
    throw new Error('Method not implemented.');
  }
  protected getDataForEdit(): void {
    throw new Error('Method not implemented.');
  }
  protected handleSubmit(formValues: any): void {
    this.isSubmitted = true
    const { idUser } = this._appSvc.currentUserValue.current
    this._patternSvc.changePassword(formValues, +idUser).subscribe({
      next: () => {
        this.handleOperationFinish(this.entity.messageUpdatePassword, false);
      },
      error: () => {
        this.isSubmitted = false
      }
    });
  }

}
