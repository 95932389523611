import { FormGeneric } from '@/_application/helpers/generic/form-generic';
import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { DocumentationEntity, typeServiceDoc } from '@modules/documentation/config/entity';
import { ResponsePublication } from '@modules/publications/interface/publication';
import { PublicationService } from '@modules/publications/services/publication.service';
import { handleIconLanguage } from '@modules/helpers/helpers';
import { ILanguage, LanguageService } from '@modules/publications/services/language.service';
import { HtmlService } from '@modules/html/services/html-loader.service';
import { ModalService } from '@/_application/services/modal.service';
import { Router } from '@angular/router';
import { LanguageChangeService } from '@/_application/services/language-change.service';
import { TranslateService } from '@ngx-translate/core';
import { PfDropdown } from '@profabric/angular-components';

import { Observable, of, Subject, Subscription } from 'rxjs';
import { pubEntity } from '@modules/publications/config/entity';
import { TagliandiService } from '@modules/Coupons/service/tagliandi.service';
@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss']
})
export class DocumentationFormPageComponent extends FormGeneric {
  @ViewChild('dropdown') dropdown: PfDropdown;
  linID = null
  private itemChange = new Subject<any>();
  public canManage = true
  public userGuid = ""
  public isLoading = true;
  save = true
  public options = typeServiceDoc
  languages: ILanguage[] = []
  languagesSub: Subscription
  public nextTag: string = ''
  public nextHour: number = 0
  public moreSixMonth: boolean = false;
  public item: ResponsePublication;
  public loadFromSlide: boolean = false;
  constructor(private readonly _pubSvc: PublicationService,
    private readonly _authSvc: AuthenticationService,
    private _htmlSvc: HtmlService,
    private modalSvc: ModalService,
    private languageChangeService: LanguageChangeService,
    private _langSvc: LanguageService,
    private readonly _pattern: TagliandiService
  ) {
    super();
    this.entity = DocumentationEntity

    this.subMenu = [
      {
        title: 'GestioneDocumentazione',
        path: '/documentation',
      }
    ]
    this._langSvc.getLanguages().subscribe((res) => {
      this._langSvc.setCurrentLanguages(res.data);
    })
  }

  protected initForm(): Observable<boolean | any> {
    
    this.languagesSub = this._langSvc.currentLanguages$.subscribe((res) => {
      this.languages = res;
    });
    const { userGuid, reseller, firstName, lastName } = this._authSvc.currentUserValue.current
    this.canManage = reseller
    this.userGuid = userGuid

    this.linID = this.languageChangeService.getSelectLanguageValue();

    this.languageChangeService.selectedLng$.subscribe((res) => {
      this.linID = res;
    })
    this.itemChange.subscribe((newItem) => {
      this.item = newItem;
      this._pubSvc.setDocuments(this.item);

      this.loadTagliandi()
    });

    return of(true);
  }
  protected deleted(): void {
    this._pubSvc.deleteAsync(this.codice).subscribe(() => {
      this.handleOperationFinish(this.entity.messageDelete);
    });
  }

  protected getDataForEdit(): void {
    this.languagesSub = this._langSvc.currentLanguages$.subscribe((res) => {
      this.languages = res;
    });
    // const { userGuid, reseller, firstName, lastName } = this._authSvc.currentUserValue.current
    // const query = {
    //   CatID: this.codice
    // }

    this._pubSvc.getAllRecordDataAsync(this.codice, true)
      .subscribe((resp) => {

        this.item = resp.data
        this.loadForm = true;
        this.save = false
        this.isLoading = false;
        this.itemChange.next(this.item);
      }, (error) => {
        this._router.navigate([this.entity.route])
      });
  }

  protected handleSubmit(formValues: any): void {
    const { cliGuid } = formValues
    const values = {
      ...formValues,
      cliGuid: !this.canManage ? this.userGuid : this.handleEmpty(cliGuid),
    }
    this.isSubmitted = true
    if (!this.isEdit) {
      this._pubSvc.saveAsync(values).subscribe({
        next: () => {
          this.handleOperationFinish(this.entity.messageAdd);
          this.isSubmitted = false
        },
        error: () => {
          this.isSubmitted = false
        }
      })
      return;
    }
    this._pubSvc.updateAsync(values, this.codice).subscribe({
      next: () => {
        this.handleOperationFinish(this.entity.messageEdit);
        this.isSubmitted = false
      },
      error: () => {
        this.isSubmitted = false

      }
    });
  }

  downloadDocument() {
    
    let path = this.item.tipoPubblicazioni.find(x => x.pubTipo === 0 && x.linId === Number(this.linID));
    if (!path) {
      const row = "Non c'è nessun documento da scaricare"
      this.translateService.instant(row)
      alert(this.translateService.instant(row))
      return
    }
    this.downloadFile(path)
  }

  downloadRicambi() {
   
    let path = this.item?.tipoPubblicazioni.find(x => x.pubTipo === 5 && x.linId === Number(this.linID));
    if (!path) {
      const row = "Non c'è nessun documento da scaricare"
      this.translateService.instant(row)
      alert(this.translateService.instant(row))
      return
    }
    this.downloadFile(path)
  }
  downloadFile(path) {
    this.dropdown.isOpen = false;
    try {
      this._htmlSvc.downloadHtml(path.pubPath).subscribe((data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const nameFile = path.pubPath.split('/');
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${nameFile[nameFile.length - 1]}`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
    } catch (error) {
      console.log(error);
    }
  }
  handleIconLanguage(language: ILanguage) {
    return handleIconLanguage(language)
  }
  downloadAttachment(documentPath: string) {
    const data = {
      pubPath: '../' + documentPath
    }
    this.downloadFile(data)
  }
  handleImage(img: string) {
    return `data:;base64,${img}`
  }
  donwloadPdf() {

  }
  loadTagliandi() {

    // const codice =this.item.matricole.matGuid;
    // console.log('Fetching data for codice:', codice);
    // this._pattern.getNextTagliandi(codice)
    //   .subscribe(
    //     (resp: any) => {
    //       console.log('Response for codice:', codice, resp);
    //       this.nextHour = resp.hour;
    //       this.nextTag = resp.service;
    //       this.moreSixMonth = resp.moreSixMont;
    //     },
    //     (error) => {
    //       console.error('Error for codice:', codice, error);
    //     }
    //   );
  }
  handleNoLanguages(option: any) {
    this.options = this.options.filter(opt => opt !== option);
  }
}
