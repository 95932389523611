import { lcidToLanguageCode } from "@/_application/data/language-codes";
import { ILanguage } from "@modules/publications/services/language.service";


export const handleRequestLanguage = (data) => {
    return data.map(setting => ({
      icon: setting.icon,
      label: setting.linNome,
      value: setting.codice.toString()
    }));
}

export const  handleDataLanguage = (data: ILanguage[]) => {
    let newData = data.map(language => {
      return {
          ...language,
          icon: handleIconLanguage(language)
      };
    });
    return newData;
}

export const handleIconLanguage = (data: ILanguage) => {
  if (data) {
    let flag = 'flag-icon-' + lcidToLanguageCode[data.linLCID]
    if(!lcidToLanguageCode[data.linLCID]) {
      flag = 'flag-icon-it'; // Default flag
    } else if(lcidToLanguageCode[data.linLCID] === 'en') {
      flag = 'flag-icon-gb'; // English flag
    } else if(lcidToLanguageCode[data.linLCID] === 'zh-HK') {
      flag = 'flag-icon-cn'; //Chinese flag
    }

    return flag
  };
  
  return 'flag-icon-it'; // Default flag
};

export const handleCaptchaLanguage = (data: ILanguage) => {
  if (data) return lcidToLanguageCode[data.linLCID];
  
  return 'it'; // Default flag
};