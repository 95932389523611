<div class="form-group relative">
    <label>{{'global.select.selectAgente' | translate}}</label>
    <ng-select [(ngModel)]="selectedOption" [disabled]="disabled" (ngModelChange)="getRowData($event)" [items]="dropdownOptions"
    [placeholder]="pubEntity.selectAnOption | translate" (clear)="clear()">
        <ng-template ng-option-tmp let-item="item">
            <div class="table">
                <table class="table">
                    <thead *ngIf="item.header">
                        <th *ngFor="let header of headers">
                            <div style="width: 10px">{{ header | translate }}</div>
                        </th>
                    </thead>
                    <tr *ngFor="let rowData of item.data">
                        <td class="pb-0"><div style="width: 10px">{{rowData.ageRagioneSociale}}</div></td>
                        <td class="pb-0"><div style="width: 10px">{{rowData.ageEmail}}</div></td>
                        <td class="pb-0"><div style="width: 10px">{{rowData.ageCitta}}</div></td>
                        <td class="pb-0"><div style="width: 10px">{{rowData.ageIndirizzo}}</div></td>
                    </tr>
                </table>
            </div>
        </ng-template>
    </ng-select>
</div>
