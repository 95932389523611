import { errorEntity } from '@/_application/config/genericEntity';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSummernoteDirective } from 'ngx-summernote';

export type onlyCharacter = '' | 'number'
@Component({
  selector: 'app-input-generic-editor-text',
  templateUrl: './input-generic-editor-text.component.html',
  styleUrls: ['./input-generic-editor-text.component.scss']
})
export class InputEditorTextGenericComponent {

  @Input() formGroup!: FormGroup;
  @Input() nameInput:any = '';
  @ViewChild(NgxSummernoteDirective) ngxSummernote: NgxSummernoteDirective;
  
  config = {
      minHeight: '200px',
  }

  constructor() {

  }

  isRequired(control: any) {
    return control.validator && control.validator({} as FormControl) && control.validator({} as FormControl).required;
  }
  validateFormat(event) {
    let key: any;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]+$/;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  ngAfterViewInit() {
   
  }

  private updateFormControlValue() {
    const editorContent = this.ngxSummernote.summernoteModel.code();
    this.nameInput.setValue(editorContent);
  }

  clearSpacesStart(event) {
    event.target.value = event.target.value.trimStart()
  }



}
