import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { FrequencyView } from '../interface/frecuency';

@Injectable({
  providedIn: 'root'
})
export class FrecuencyService extends BaseHttpGeneric<any, FrequencyView>  {

  constructor() {
    super()
    this.controller = 'Frequenze'
  }

  getFrecuencies(params): any {
    const url = this.handleUrl(false)
      return this.http.get(`${url}`, { params })
  }
}
