import { ValueNav } from '@/_application/interfaces/select';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.scss']
})
export class FormBaseComponent implements OnInit {
  @Input() isSubmitted: boolean = false
  @Input() isSubmittedTestEmail: boolean = false
  @Input() hiddenButtonCancel: boolean = false
  @Input() hiddenTestEmailBtn: boolean = true
  @Input() form!: FormGroup;
  @Input() selectedNav: ValueNav = {
    opt: '',
    form: false
  }
  @Input() isDeleted = false;
  @Input() entity: any
  profile = false

  @Output() submitData = new EventEmitter()
  @Output() testConecction = new EventEmitter()
  @Output() deletedData = new EventEmitter()
  @Output() cancelData = new EventEmitter()
  public user: UserCurrent | null = null

  constructor(private _router: Router, private readonly _authSvc: AuthenticationService) {
    this.user = this._authSvc.currentUserValue
  }
  ngOnInit(): void {
    this.profile = this._router.url === "/profile"
  }

  handleSubmited() {
    this.submitData.emit();
  }

  handleTestConnection() {
    this.testConecction.emit();
  }

  handleCancel() {
    this._router.navigate([this.entity.route])
    this.cancelData.emit()
  }

  handleDeleted() {
    this.deletedData.emit();
  }
}
