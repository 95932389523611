import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Branch } from '../interfaces/branches';

@Injectable({
  providedIn: 'root'
})
export class BranchesService extends BaseHttpGeneric<any, Branch>  {
  private branchesFormState: any;
  constructor() {
    super()
    this.controller = 'Filiali'
  }

  saveBranchesFormState(formState: any) {
    this.branchesFormState = formState;
  }

  getBranchesFormState() {
    return this.branchesFormState;
  }

  clearFormBranchesState() {
    this.branchesFormState = null;
  }
}
