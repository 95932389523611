<div class="card">
    <div class="card-header p-2" [ngClass]="{'not-padding': notTile.length > 0}">
        <h3 *ngIf="notTile.length ===0" class="card-title mb-0">{{'global.utils.informazioniGenerali' | translate}}</h3>
        <ul class="nav nav-pills" *ngIf="notTile.length > 0">
            <li *ngFor="let nav of notTile" class="nav-item"><a role="button" class="nav-link"
                    [ngClass]="{active : nav.value.opt === selectedNav.opt}" (click)="handleSelectedNav(nav.value)"
                    data-toggle="tab">{{nav.label | translate }}</a></li>
        </ul>
    </div>
    <form [formGroup]="form" (submit)="handleSubmited()" novalidate class="form-refresh">
        <div class="card-body">
            <div class="wrapper wrapper-form" [ngClass]="{'not-scroll-full':selectedNav.opt}">
                <ng-content #body> </ng-content>
            </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer d-flex" *ngIf="!selectedNav.opt || selectedNav.form">
            <button [disabled]="isSubmitted" (click)="handleDeleted()"
                *ngIf="isDeleted && user.current.role.canDelete && authorize" type="button"
                class="btn bg-gradient-danger mr-auto" style="margin-left:7.5px;">{{'global.utils.eliminare' | translate}}</button>
            <button [disabled]="isSubmitted" (click)="handleCancel()" type="button"
                class="btn bg-gradient-secondary">{{'global.utils.annulla' | translate}}</button>
            <button *ngIf="user.current.role.canWrite && saveData" type="submit" class="btn bg-gradient-success ml-2" style="margin-right:15px;">
                {{!isSubmitted ?('global.utils.salva' | translate) : ''}}
                <ng-container *ngIf="isSubmitted">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{'global.utils.caricamento' | translate}}...
                </ng-container>
            </button>
            <button *ngIf="isDownload" type="button" (click)="handleDonwload()"
                class="btn bg-info ml-2">{{entity.download}}</button>
        </div>
    </form>
</div>