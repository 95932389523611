<div class="card-body">
    <div class="row">
        <div class="col-sm-12 mb-3" style="text-align: end;">
            <button (click)="handleNavigate()" type="button" class="btn btn-block btn-primary">{{'global.utils.nuovoContatto' | translate}}</button>
        </div>
        <div class="col-md-12">
            <app-generic-grid [updateTitleState]="false" [entity]="entity" [ConfigTable]="configTable" [data]="source" [meta]="meta"
                (nextPage)="changePage($event)" (search)="onSearch($event)" (report)="generatedFile($event)"
                (sortColumn)="sortColumn($event)" (filterColumn)="filterColumn($event)"></app-generic-grid>
        </div>
    </div>
</div>