import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-checkbox-generic-custom',
  templateUrl: './checkbox-generic-custom.component.html',
  styleUrls: ['./checkbox-generic-custom.component.scss']
})
export class CheckboxGenericCustomComponent implements OnInit {
  @Input() label = '';
  @Input() nameInput = '';
  @Input() placeholder = '';
  @Input() align = '';
  @Input() formGroup!: FormGroup;
  @Input() showError: boolean = false;
  @Input() horizontal: boolean = false;
  @Input() disabled: boolean = false;
  @Input() module = '';
  @Input() view = 'form';

  constructor(private readonly translate: TranslateService) {

  }
  ngOnInit(): void {
 
  }

  toggleSwitch() {
  const currentValue = this.formGroup.get(this.nameInput).value;
  this.formGroup.get(this.nameInput).setValue(!currentValue);
}

}
