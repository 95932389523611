import { ExportFormat } from '@/_application/config/config';
import { PagedListGeneric } from '@/_application/helpers/generic/paged-list-generic';
import { SortType } from '@/_application/interfaces/response';
import { SearchApi } from '@/_application/interfaces/search';
import { Component, Input } from '@angular/core';
import { ConfigTableContatti } from './configTable';
import { TableProps } from '@/_application/interfaces/generic-table';
import { ContattiEntity } from './contatti';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { ContattiService } from '@modules/Contacts/service/contatti.service';
import { TypeUSerUrl } from '@modules/Contacts/config/type-user';

@Component({
  selector: 'app-form-generic-contatti',
  templateUrl: './form-generic-contatti.component.html',
  styleUrls: ['./form-generic-contatti.component.scss']
})
export class FormGenericContattiComponent extends PagedListGeneric<any> {
  public configTable: TableProps = ConfigTableContatti;
  @Input() userId = null
  @Input() type = ''
  constructor(private router: Router, private readonly _patternSvc: ContattiService) {
    super()
    this.entity = ContattiEntity
  }
  protected initConfig(): void {
    const url = this.router.url
    const [_, returnUrl, __, id] = url.split('/')
    const idSplit = id.split('?')
    this.entity.params = {
      userId: idSplit[0],
      returnUrl,
      type: this.type
    }
  }
  protected list(evt: SearchApi): void {
    const url = this.router.url
    const [_, returnUrl, __, id] = url.split('/')
    const idSplit = id.split('?')
    this.loading = true;
    const query = {
      UserId: idSplit[0],
      ConTipo: TypeUSerUrl[returnUrl]
    }
    this._patternSvc
      .getAllAsyncWhitQuery(evt, query)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((data) => {
        this.handleData(data);
        this.cd.detectChanges();
      });
  }
  protected generateExcelPdf(method: ExportFormat, object: string, queryColumn?: any, orderType?: SortType, orderBy?: string): void {
  }

  handleNavigate() {
    const url = this.router.url
    const [_, returnUrl, __, id] = url.split('/')
    const idSplit = id.split('?')
    this.router.navigate(['/contatti'], {
      queryParams: {
        userId: idSplit[0],
        returnUrl,
        type: this.type
      }
    })
  }

}
