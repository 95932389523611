import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { RoleView } from '../interface/role';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseHttpGeneric<any, RoleView> {

  constructor() {
    super()
    this.controller = 'Role'
  }
}
