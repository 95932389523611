import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Agents } from '../interfaces/agents';

@Injectable({
  providedIn: 'root'
})
export class AgentsService extends BaseHttpGeneric<any, Agents>  {
  private agentFormState: any;
  constructor() {
    super()
    this.controller = 'Agenti'
  }

  saveAgentFormState(formState: any) {
    this.agentFormState = formState;
  }

  getAgentFormState() {
    return this.agentFormState;
  }

  clearFormAgentState() {
    this.agentFormState = null;
  }
}
