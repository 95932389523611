import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { regex } from '../utils/regex';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export class FormValidations {

    public passwordConfirming(c: AbstractControl): { passwordInvalid: boolean } | null {
        const passwdControl = c.get('password');
        const confirmPasswdControl = c.get('confirmPassword');
        if (passwdControl?.value !== confirmPasswdControl?.value) {
            return { passwordInvalid: true };
        }
        return null;
    }

    public validatePassword(c: AbstractControl): { passwordInvalid: boolean } | null {
        const passwdControl = c.get('password');
        const confirmPasswdControl = c.get('cPassword');
        if (passwdControl?.value !== confirmPasswdControl?.value) {
            return { passwordInvalid: true };
        }
        return null;
    }

    public validateEmptyValue(control: AbstractControl) {
        const empty = control.value ? control.value.trim().length === 0 : false;
        return empty ? { 'required': true } : null;
    }

    public emailValidate(control: AbstractControl) {
        const value = control.value
        if (!value) return null

        const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

        if (!validEmail) return {
            email: true,
        };

        return null;

    }

    public urlValidate() {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value || value.trim().length === 0) return null;

            const validUrl = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g.test(value);
            return !validUrl ? { url: true } : null;
        }
    }

    emailValidateNew(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) return null;

            const normalizedRegex = new RegExp(regex.email);
            const validEmail = normalizedRegex.test(value)

            return !validEmail ? { email: true } : null;
        }
    }

    phoneCustomValidate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value || value.trim().length === 0) return null;

            const phonePattern = /^(\+|00)?(\(\+\d{1,4}\)|\(\d{1,4}\)|\d{1,4})[\s\-]?\(?[\da-zA-Z]{1,4}\)?[\s\-]?[\da-zA-Z]{1,4}([\s\-]?[\da-zA-Z]{2,6}){0,4}$/;

            const valid = phonePattern.test(value);

            return !valid ? { phone: true } : null;
        }
    }


    phoneValidate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value || value.trim().length === 0) return null;

            const valid = regex.phone.test(value)

            return !valid ? { phone: true } : null;
        }
    }


    phoneLengthValidate(): ValidatorFn {
        // return (control: AbstractControl): ValidationErrors | null => {
        //     const value = control.value;

        //     if (!value || value.trim().length === 0) return null;

        //     try {
        //         const phoneNumber = parsePhoneNumberFromString(value);
        //         const valid = phoneNumber && phoneNumber.isValid();
        //         const lengthValid = value.replace(/\D/g, '').length <= 15; // Remove non-digits and check length
        //         return !valid || !lengthValid ? { phone: true } : null;
        //     } catch (error) {
        //         return { phone: true };
        //     }
        // };
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value || value.trim().length === 0) return null;

            const valid = value.trim().length > 5

            return !valid ? { phoneLength: true } : null;
        }
    }

    faxValidate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value || value.trim().length === 0) return null;

            const valid = regex.fax.test(value)

            return !valid ? { fax: true } : null;
        }
    }

    faxLengthValidate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value || value.trim().length === 0) return null;

            const valid = value.trim().length > 5

            return !valid ? { faxLength: true } : null;
        }
    }

    dateMinValidate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const yearMin = 1900
            if (!value) return null;

            const normalizedDate = new Date(value)
            return normalizedDate.getFullYear() > yearMin ? null : { dateMin: true }
        }
    }

    isValidUrl = (url: string) => {
        try {
            return Boolean(new URL(url));
        }
        catch (e) {
            return false;
        }
    }
}
