import { Injectable } from '@angular/core';
import { Agency } from '@modules/administration/general-settings/interface/agency';
import { General } from '@modules/administration/general-settings/interface/general';
import { NotificationEmail } from '@modules/administration/general-settings/interface/notification-email';
import { AgencyService } from '@modules/administration/general-settings/service/agency.service';
import { GeneralService } from '@modules/administration/general-settings/service/general.service';
import { NotificationEmailService } from '@modules/administration/general-settings/service/notification-email.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private currentEntity$ = new BehaviorSubject<string>("");
  currentEntity = this.currentEntity$.asObservable();

  public settings$ = new BehaviorSubject<General>({} as General);
  public settings = this.settings$.asObservable();

  public settingNotifyEmail$ = new BehaviorSubject<NotificationEmail>({} as NotificationEmail);
  public settingNotifyEmail = this.settingNotifyEmail$.asObservable();

  public settingAgency$ = new BehaviorSubject<Agency>({} as Agency);
  public settingAgency = this.settingAgency$.asObservable();

  public customlogo$ = new BehaviorSubject<string>('' as string);
  public customlogo = this.customlogo$.asObservable();

  public customIcona$ = new BehaviorSubject<string>('' as string);
  public customlcona = this.customIcona$.asObservable();

  public version$ = new BehaviorSubject<{
    name:string;
    private:boolean;
    version:string;
  }>(undefined);
  public version = this.version$.asObservable();

  public defaultPath = ''
  public pathImage = 'Shared/Azienda/img'
  public currentImageLogo = '';

  constructor(    
    private readonly _generalSetingSvc: GeneralService,
    private readonly _patternNotifySvc: NotificationEmailService,
    private readonly _patternAgencySvc: AgencyService,
    private titleService: Title
  ) { }


  initialData() {
    this.getSettings();
    this.getNotifyEmail();
    this.getAgency();
  }

  getSettings() {
    this._generalSetingSvc
      .getAllAsyncWhitQuery({ currentPage: 1, pageSize: 1 })
      .subscribe((data) => {
        const { data: result,version } = data
        if (result.length > 0) {
          this.setCurrentSettings(result[0])
          this.getImageLogo(result[0])
        }
        const normalizedVersion = JSON.parse(version)
        this.version$.next(normalizedVersion)
      });
  }

  getImageLogo(settings:General){
    const { server, local, production } = environment
      if (production) {
        const { apiUrl } = server
        let pathUrl = apiUrl.replace(/\/api$/, "");
        this.defaultPath = `${pathUrl}/${this.pathImage}/`
        let newPath = `${pathUrl}/${this.pathImage}/${settings?.logoSito ? settings.logoSito : ''}` 
        let newPathIco = `${pathUrl}/${this.pathImage}/${settings?.iconaSito ? settings.iconaSito : ''}` 
        this.setLogoCurrent(newPath)
        this.setIconaCurrent(newPathIco)
      }else {
        const { apiUrl } = local
        let pathUrl = apiUrl.replace(/\/api$/, "");
        this.defaultPath = `${pathUrl}/${this.pathImage}/`
        let newPath = `${pathUrl}/${this.pathImage}/${settings?.logoSito ? settings.logoSito : ''}` 
        let newPathIco = `${pathUrl}/${this.pathImage}/${settings?.iconaSito ? settings.iconaSito : ''}` 
        this.changeFavicon(settings?.iconaSito)
        this.setLogoCurrent(newPath)
        this.setIconaCurrent(newPathIco)
      }
  }

  getNotifyEmail() {
    this._patternNotifySvc
      .getAllAsyncWhitQuery({ currentPage: 1, pageSize: 1 })
      .subscribe((data) => {
        const { data: result } = data
        if (result.length > 0) {
          this.setCurrentSettingsNotifyEmail(result[0])
        }
      });
  }

  getAgency() {
    this._patternAgencySvc
      .getAllAsyncWhitQuery({ currentPage: 1, pageSize: 1 })
      .subscribe((data) => {
        const { data: result } = data
        if (result.length > 0) {
          this.setCurrentSettingsAgency(result[0])
        }
      });
  }

  setLogoCurrent(value: string) {
    this.customlogo$.next(value);
  }

  setIconaCurrent(value: string) {
    this.customIcona$.next(value);
  }

  setCurrentEntity(value: string) {
    this.currentEntity$.next(value);
  }

  setCurrentSettings(value: General) {
    this.settings$.next(value);
    this.changeTitle(value.nomeSito)
    this.changeFavicon(value.iconaSito)
  }

  changeTitle(newTitle: string ) {
    this.titleService.setTitle(newTitle);
  }

  changeFavicon(name:string) {
    const link: HTMLLinkElement = document.querySelector('link[rel="icon"]') || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = `${this.defaultPath}${name}`
    document.head.appendChild(link);
  }

  setCurrentSettingsNotifyEmail(value: NotificationEmail) {
    this.settingNotifyEmail$.next(value);
  }

  setCurrentSettingsAgency(value: Agency) {
    this.settingAgency$.next(value);
  }

}
