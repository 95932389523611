import { formatITCurrentDate, formatITCurrentDateWithHours } from '@/_application/helpers/format-dates';
import { ModalService } from '@/_application/services/modal.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GaranzieService } from '@modules/gestione-garanzie/services/garanzie.service';
import { PublicationAvariableView } from '@modules/publications/interface/publication';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-update-hour',
  templateUrl: './modal-update-hour.component.html',
  styleUrls: ['./modal-update-hour.component.scss']
})
export class ModalUpdateHourComponent implements OnInit {
  @Input() public item: PublicationAvariableView = null;

  public hasError: boolean;
  public loadForm = false;
  public isSubmitted: boolean;
  public form!: FormGroup;

  constructor(private readonly _fb: FormBuilder,
    private readonly _modalSvc: ModalService,
    private readonly _pattern: GaranzieService,
    private readonly toastr: ToastrService,
    private readonly translateService: TranslateService

  ) {
    this.hasError = false;
    this.isSubmitted = false;
  }


  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    const value = this.item.garOreEffettuate === 0 ? null : this.item.garOreEffettuate
    this.form = this._fb.group({
      oreLavorate: [value, [Validators.required]],
      prossimaManutenzione: [null]
    })

    this.form.controls['prossimaManutenzione'].disable()
  }

  handleSubmit() {
    const { oreLavorate } = this.form.value
    const currentDate = formatITCurrentDateWithHours();
    this._pattern.updateHour(oreLavorate, this.item.garID, currentDate).subscribe({
      next: () => {
        this.toastr.success(this.translateService.instant('garanzie.messageUpdateHour'))
        this._modalSvc.setShowModal(false, undefined)
        this.isSubmitted = false
        this.item.garOreEffettuate = oreLavorate
        this.item.garDataPrimoAvviamento = currentDate
        this.form.reset(undefined)
      },
      error: () => {
        this.isSubmitted = false
      }
    });
  }

  handleCancel() {
    this._modalSvc.setShowModal(false, undefined)
  }
}
