import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Distributors } from '../interfaces/distributor';

@Injectable({
  providedIn: 'root'
})
export class DistributorsService extends BaseHttpGeneric<any, Distributors>  {
  private distributorFormState: any;
  constructor() {
    super()
    this.controller = 'Distributori'
  }

  saveDistributorFormState(formState: any) {
    this.distributorFormState = formState;
  }

  getDistributorFormState() {
    return this.distributorFormState;
  }

  clearFormDistributorState() {
    this.distributorFormState = null;
  }
}
