export const authLocalKey = 'net_auth_key';
export const authLocalEmail = 'net_auth_email';
export const authLocalRole = 'net_auth_role'; 
export const authLocalUser = 'user'; 

export const passwordRegexPattern: string = '^(?=.*[A-Z])(?=.*[0-9]).{10,}$';

// erros
export interface Sweet {
    title: string;
    html?: string;
    btnOk?: string;
    btnCancel?: string;
}

export const loginErrors: Sweet = {
    title: 'error :(',
    html: ''
}

export enum AlertType {
  Basic = 'basic',
  Confirm = 'confirm',
  Warning='warning'
}

export enum AlertTypeIcons {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Question = 'question',
  Error = 'error'
}

export enum ExportFormat {
  Excel = 1,
  Pdf = 2
}

export interface ObjectDb{
  search:string
  columns: {
    header: string;
    field: string;
    width: number | undefined;
}[];
}

