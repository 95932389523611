<div class="form-group" [formGroup]="formGroup">
    <label [for]="nameInput" *ngIf="showLabel">{{ label | translate }} *</label>
    <div class="input-group">
        <div class="custom-file">
            <input type="file" name="photo" 
                [formControlName]="nameInput" [accept]="accept" class="custom-file-input"
                (change)="updateFileName($event)"
                >
            <label class="custom-file-label" >{{ selectedFileName }}</label>           
        </div>
    </div> 
    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
        validation: 'required',
        message: pubEntity.errorFieldMandatory | translate,
        control: formGroup.controls[nameInput]
    }"></ng-container>

    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
        <ng-container *ngIf="showError && control && control.hasError(validation)">
            <div class="wrapper">
            <div class="text-danger">
                {{ message }}
            </div>
            </div>
        </ng-container>
    </ng-template>   
</div>