<div class="card-header p-2" [ngClass]="{'not-padding': tabs.length > 0}">
    <div [ngStyle]="{'margin-left': marginLeftH3}">
        <h3 *ngIf="tabs.length ===0" class="card-title">{{entity?.titleForm || 'global.utils.informazioniGenerali' | translate}}</h3>
        <ul class="nav nav-pills" *ngIf="tabs.length > 0">
            <li *ngFor="let nav of tabs" class="nav-item"><a role="button" class="nav-link"
                [ngClass]="{active: tabs.length > 1 && nav.value.opt === selectedNav.opt}" (click)="handleSelectedNav(nav.value)"
                    data-toggle="tab">{{nav.label | translate}}</a></li>
        </ul>
        
    </div>
</div>
<ng-content #body> </ng-content>