import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Nations } from '../interfaces/nations';

@Injectable({
  providedIn: 'root'
})
export class NationsService extends BaseHttpGeneric<any, Nations>  {

  constructor() {
    super()
    this.controller = 'Nazioni'
  }
}
