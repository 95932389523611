import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { BYPASS_TOKEN } from '../interceptor/jwt.interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService implements TranslateLoader {
  public controller = "Localization"
  constructor(private http: HttpClient) { }

  private injector: Injector = inject(Injector)

  getTranslation(lang: string): Observable<any> {
    const _authSvc = this.injector.get(AuthenticationService)
    return this.http.get(`${this.handleUrl()}/`, {
      params: {
        culture: lang
      },
      context: new HttpContext().set(BYPASS_TOKEN, true)
    }).pipe(
      map((resp) => {
        if (!_authSvc.isLogged() && _authSvc.statusAppCurrent === "not-verifed") {
          _authSvc.changeStatus("verifed")
        } else if (_authSvc.currentUserValue && _authSvc.currentUserValue.load && _authSvc.statusAppCurrent === "not-verifed") {
          _authSvc.changeStatus("verifed")
        }
        return resp
      })
    )
  }

  handleUrl(auth = false) {
    const { server, local, production } = environment
    if (production) {
      const { authenticate, apiUrl } = server
      const url = auth ? authenticate : apiUrl
      return `${url}/${this.controller}`
    }
    const { authenticate, apiUrl } = local
    const url = auth ? authenticate : apiUrl
    return `${url}/${this.controller}`
  }
}
