import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Module, ModuleView } from '../interfaces/module';
import { Observable } from 'rxjs';
import { ResponseApi } from '@/_application/interfaces/response';

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends BaseHttpGeneric<Module, ModuleView> {

  private moduleFormState: any;
  constructor() {
    super()
    this.controller = 'Module'
  }

  addLanguge(data: any): Observable<ResponseApi<number>> {
    const url = this.handleUrl()
    const normalizedData = this.validateEmptyData(data)
    return this.http.post<ResponseApi<number>>(
      `${url}/add-language`,
      normalizedData
    );
  }

  saveModuleFormState(formState: any) {
    this.moduleFormState = formState;
  }

  getModuleFormState() {
    return this.moduleFormState;
  }

  clearFormModuleState() {
    this.moduleFormState = null;
  }
}
