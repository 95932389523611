import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { LanguageChangeService } from '@/_application/services/language-change.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ModuleService } from '@modules/administration/module/services/module.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, finalize } from 'rxjs';

@Component({
  selector: 'app-select-module',
  templateUrl: './select-module.component.html',
  styleUrls: ['./select-module.component.scss']
})
export class SelectModuleComponent extends HelperSelect implements OnInit, OnChanges, OnDestroy {

  @Input() showError: boolean = false;
  @Input() control: any;
  @Input() change: string = "";
  @Input() clear: string = "";
  @Input() roleId: number | undefined = undefined;
  @Input() userId: number | undefined = undefined;
  langSvc = new Subscription();
  linID = 1
  constructor(
    private _patternSvc: ModuleService,
    private readonly translateSvc: TranslateService,
    private _languageChangeService: LanguageChangeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.linID = Number(this.translateSvc.currentLang)
    this.handleData("")

    this.langSvc = this._languageChangeService.selectedLng$.subscribe(elem => {
      this.linID = Number(elem)
      this.handleData("")
    })
  }

  ngOnDestroy(): void {
    this.langSvc.unsubscribe();
  }

  protected handleData(search: string, query?: any): void {
    this.loading = true
    const filters = {}
    if (this.roleId) filters['roleId'] = this.roleId
    if (this.userId) filters['userId'] = this.userId
    if (this.linID) filters['linID'] = this.linID

    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
    }, {
      ...filters
    }).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp

      const options = data.map((elem): OptionsSelect => {
        const { name, id } = elem
        return {
          label: name,
          value: id
        }
      })

      this.rows = options

    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.change) {
      const value = changes.change.currentValue
      if (!value) return
      this.handleData("")
      this.clear = this.clear === "" ? 'change' : `${value}`
    }
  }

}
