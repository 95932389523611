import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, switchMap } from 'rxjs';
import { authLocalEmail, authLocalKey, authLocalRole, authLocalUser } from '../../../_application/config/config';
import { ResponseAuthenticate, UserCurrent } from '../interfaces/user';
import { ResponseApi } from '@/_application/interfaces/response';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@modules/users/services/user.service';
import { LanguageChangeService } from '@/_application/services/language-change.service';
import { StatusApp } from '@/_application/interfaces/global';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserCurrent>;
  public currentUser: Observable<UserCurrent>;
  statusApp$: Observable<StatusApp>;
  statusAppSubject: BehaviorSubject<StatusApp>;
  controller = ""

  get statusAppCurrent(): StatusApp {
    return this.statusAppSubject.value;
  }
  constructor(
    private http: HttpClient,
    private readonly translate: TranslateService,
    private _userService: UserService,
    private _lngService: LanguageChangeService
  ) {
    this.currentUserSubject = new BehaviorSubject<UserCurrent>({
      authkey: localStorage.getItem(authLocalKey),
      authemail: localStorage.getItem(authLocalEmail),
      userRoleDescription: localStorage.getItem(authLocalRole)!,
      current: localStorage.getItem(authLocalUser) ? JSON.parse(localStorage.getItem(authLocalUser)) : null,
      load: false
    });
    this.currentUser = this.currentUserSubject.asObservable();
    this.controller = 'Authenticate'
    this.statusAppSubject = new BehaviorSubject<StatusApp>('not-verifed');
    this.statusApp$ = this.statusAppSubject.asObservable();

  }

  get currentUserValue(): UserCurrent {
    return this.currentUserSubject.value;
  }
  handleUrl(auth = false) {
    const { server, local, production } = environment
    if (production) {
      const { authenticate, apiUrl } = server
      const url = auth ? authenticate : apiUrl
      return `${url}/${this.controller}`
    }
    const { authenticate, apiUrl } = local
    const url = auth ? authenticate : apiUrl
    return `${url}/${this.controller}`
  }
  login(email: string, password: string) {
    return this.http.post<ResponseApi<ResponseAuthenticate>>(`${this.handleUrl(true)}/Login`, { email, password })
      .pipe(map(resp => {
        if (resp) {
          const currentLng = localStorage.getItem('language_default') ? localStorage.getItem('language_default') : '1';

          this._userService.updateLanguage({ linID: Number(currentLng) }, Number(resp.data.user.idUser)).subscribe();
          resp.data.user.linID = currentLng;
          this._lngService.setSelectLanguage(currentLng);
          this.createCookieDetails(resp.data);
        }
        this.translate.use(this.currentUserValue.current.linID);
        return this.currentUserValue;
      }));
  }

  register(user) {
    return this.http.post<any>(`${this.handleUrl(true)}/Register`, user)
  }
  registerContact(user) {
    return this.http.post<any>(`${this.handleUrl(true)}/Register-contact`, user)
  }
  updateContact(user, iduser) {
    return this.http.put<any>(`${this.handleUrl(true)}/UpdateContatti/${iduser}`, user)
  }
  deleteContact(iduser) {
    return this.http.delete<any>(`${this.handleUrl(true)}/${iduser}`)
  }
  registerV2(user, userType: string = 'Armatori') {
    if(!user.userPartitaIva) {
      return this.http.post<any>(`${this.handleUrl(true)}/Register-user`, user)
    }

    return this.http.get(`${this.handleUrlUserType(userType)}${user.userPartitaIva}`).pipe(
      switchMap((resp) => {
        return this.http.post<any>(`${this.handleUrl(true)}/Register-user`, user)
      })
    )
  }

  registerReseller(reseller) {
    return this.http.post<any>(`${this.handleUrl(true)}/RegisterReseller`, reseller)
  }

  validateRegister(code: string, userId: string, nazioni: string) {
    return this.http.get(`${this.handleUrl(true)}/ValidateRegistration/${code}/${userId}/${nazioni}`)
  }

  getWhitGuid(code: string) {
    return this.http.get<ResponseApi<any>>(`${this.handleUrl(true)}/WhitGuid/${code}`)
  }

  createCookieDetails(elem: ResponseAuthenticate, cb?: Function) {
    const { userRoleDescription, email } = elem.user
    const { accessToken } = elem.token
    this.currentUserSubject.next({
      authemail: email,
      authkey: accessToken,
      userRoleDescription,
      current: elem.user,
      load: true
    });
    localStorage.setItem(authLocalKey, accessToken);
    localStorage.setItem(authLocalEmail, email);
    localStorage.setItem(authLocalRole, userRoleDescription);
    localStorage.setItem(authLocalUser, JSON.stringify(elem.user));
    cb && cb();
  }

  deaAthorizeToken() {
    localStorage.removeItem(authLocalKey);
    localStorage.removeItem(authLocalEmail);
    localStorage.removeItem(authLocalRole);
    localStorage.removeItem(authLocalUser);
    this.currentUserSubject.next(null);
  }

  resetPasswod(email: string, languageId: number) {
    return this.http.post(`${this.handleUrl(true)}/ResetPassword`, { email, languageId })
      .pipe(map(pass => {
        return pass;
      }));
  }

  changePasswordForgotten(code: string, password: string, confirmPassword: string) {
    return this.http.put<{ description: string }>(
      `${this.handleUrl(true)}/ChangePassword`, { code, password, confirmPassword })
      .pipe(map(pass => {
        return pass;
      }));
  }

  isLogged(): boolean {
    const user = this.currentUserValue;

    if(!user) return false;

    const empty = Object.entries(user).length === 0
    if (empty || !user.current) {
      return false;
    }
    return true;
  }

  verifyCredentials() {
    return this.http.get<ResponseApi<ResponseAuthenticate>>(`${this.handleUrl(true)}/Me`)
  }

  updateProfile(values) {
    this.currentUserSubject.next({
      ...this.currentUserValue,
      current: {
        ...this.currentUserValue.current,
        ...values
      }
    })
  }

  changeStatus(status: StatusApp) {
    this.statusAppSubject.next(status);
  }

  registerResellerV2(reseller) {
    return this.http.get(`${this.handleUrlV2()}${reseller.rif}`).pipe(
      switchMap((resp) => {
        return this.http.post<any>(`${this.handleUrl(true)}/RegisterReseller`, reseller)
      })
    )
  }

  handleUrlV2(auth = false) {
    const { server, local, production } = environment
    if (production) {
      const { authenticate, apiUrl } = server
      const url = auth ? authenticate : apiUrl
      return `${url}/Clienti/partita-iva/`
    }
    const { authenticate, apiUrl } = local
    const url = auth ? authenticate : apiUrl
    return `${url}/Clienti/partita-iva/`
  }

  handleUrlUserType(userType = '', auth = false) {
    const { server, local, production } = environment
    if (production) {
      const { authenticate, apiUrl } = server
      const url = auth ? authenticate : apiUrl
      return `${url}/${userType}/partita-iva/`
    }
    const { authenticate, apiUrl } = local
    const url = auth ? authenticate : apiUrl
    return `${url}/${userType}/partita-iva/`
  }
  private profileFormState: any;
  saveProfileFormState(formState: any) {
    this.profileFormState = formState;
  }

  getProfileFormState() {
    return this.profileFormState;
  }

  clearFormProfileState() {
    this.profileFormState = null;
  }
}
