import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfficineService extends BaseHttpGeneric<any, any> {
  private officineFormState: any;
  constructor() {
    super()
    this.controller = 'Officine'
  }

  saveOfficineFormState(formState: any) {
    this.officineFormState = formState;
  }

  getOfficineFormState() {
    return this.officineFormState;
  }

  clearFormOfficineState() {
    this.officineFormState = null;
  }
}
