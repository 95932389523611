import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageChangeService {
  languageChanged = new Subject<string>();

  selectedLng = new BehaviorSubject<string>('1');
  selectedLng$ = this.languageChanged.asObservable();

  authLng = new BehaviorSubject<string>(localStorage.getItem("language_default") || '1');
  authLng$ = this.authLng.asObservable();

  constructor() { 
    
  }

  setSelectLanguage(lng: string) {
    this.selectedLng.next(lng);
  }

  getSelectLanguage() {
    return this.selectedLng.asObservable();
  }

  setAuthLng(lng: string) {
    this.authLng.next(lng);
  }

  getAuthLng() {
    return this.authLng.value;
  }

  getSelectLanguageValue() {
    return this.selectedLng.value;
  }

}
