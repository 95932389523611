export const lcidToLanguageCode = {
    9: 'en',  // Inglés
    10: 'es',  // Español
    30724: 'zh-HK', // Chino simplificado
    4: 'zh-HK', // Chino simplificado
    31748: 'zh-HK', // Chino simplificado
    12: 'fr',  // Francés
    7: 'de',  // Alemán
    132: 'de',  // Alemán
    4096: 'de',  // Alemán
    57: 'in',  // Hindi
    1: 'sa',  // Árabe
    31814: 'sa',  // Árabe
    35: 'ru',  // Ruso
    16: 'it',  // Italiano
    17: 'jp',  // Japonés
    2117: 'bd',  // Bengali
    70: 'pk',  // Punjabi
    31: 'tr',  // Turco
    66: 'tr',  // Turco
    22: 'pt',  // Portugués
    18: 'kr',  // Coreano
    31764: 'no',  // Noruego
    6: 'dk',  // Danés
    1053: 'se',  // Sueco
    5: 'cz',  // Checo
    27: 'sk',  // Eslovaco
    1020: 'is',   // Islandés
    1034: 'es',  // Español (España)
    1035: 'fi',  // Finlandés
    1045: 'pl',  // Polaco
    1038: 'hu',  // Húngaro
    1043: 'nl',  // Neerlandés
    1039: 'is',  // Islandés
    1048: 'ro',  // Rumano
    2070: 'pt',  // Portugués (Portugal)
  };
  