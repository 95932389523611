import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from '@pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { StoreModule } from '@ngrx/store';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { authReducer } from './_application/store/auth/reducer';
import { uiReducer } from './_application/store/ui/reducer';
import { JwtInterceptorService } from './_application/interceptor/jwt.interceptor.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxCaptchaModule } from 'ngx-captcha';
import { UpdateProfileComponent } from './pages/components/update-profile/update-profile.component';
import { ChangePasswordComponent } from './pages/components/change-password/change-password.component';
import { CustomComponentModule } from './_application/components/custom-component.module';
import { MatSortModule } from '@angular/material/sort';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationService } from './_application/services/translation-service.service';
import { RecaptchaModule } from 'ng-recaptcha';
import {IvyCarouselModule} from 'carousel-angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DocumentationComponent } from './modules/documentation/documentation.component';
import { DocumentationPageComponent } from './modules/documentation/pages/index-page/index-page.component';
import { DocumentationFormPageComponent } from './modules/documentation/pages/form-page/form-page.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

registerLocaleData(localeEn, 'en-EN');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        BlankComponent,
        ProfileComponent,
        DashboardComponent,
        MainMenuComponent,
        SubMenuComponent,
        UpdateProfileComponent,
        ChangePasswordComponent,
        DocumentationComponent,
        DocumentationPageComponent,
        DocumentationFormPageComponent,
    ],
    imports: [
        ProfabricComponentsModule,
        CarouselModule,
        CommonModule,
        BrowserModule,
        StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgSelectModule,
        MatSortModule,
        QRCodeModule,
        NgxCaptchaModule,
        CustomComponentModule,
        RecaptchaModule,
        IvyCarouselModule,
        MatSlideToggleModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationService,
                deps: [HttpClient],
            },
        }),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        TooltipModule.forRoot()
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
