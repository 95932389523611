import Swal from 'sweetalert2';
import { AlertType, AlertTypeIcons, Sweet } from '../config/config';
import { TranslateService } from '@ngx-translate/core';
import { Inject } from '@angular/core';

export function getSweetAlert(
  alertType: AlertType | 'resend_email',
  alertTypeIcons: AlertTypeIcons,
  data: Sweet,
  callback: any = null,
  textButton: string = ''
): Function {


  let sweet: any = null;
  if (alertType == 'basic') {
    sweet = Swal.fire({
      title: data.title,
      icon: alertTypeIcons,
      html: data.html,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: alertTypeIcons === AlertTypeIcons.Error ? 'bg-dangerSweet' : 'bg-successSweet'
      }
    })
  }

  if (alertType == 'confirm') {
    sweet = Swal.fire({
      title: data.title,
      icon: alertTypeIcons,
      html: data.html,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: data.btnCancel || 'Annulla',
      customClass: {
        confirmButton: 'outline-button-sweet bg-danger',
        cancelButton: 'outline-button-sweet'
      },
      reverseButtons: true
    }).then((result) => {
      if (callback && result.isConfirmed) {
        callback();
      }
    })
  }
  if(alertType=='warning'){
    Swal.fire({
      title: data.title,
      html: data.html,
      icon: 'warning',
      confirmButtonText: 'Chiudi'
    });
  }
  if (alertType == 'resend_email') {
    sweet = Swal.fire({
      title: data.title,
      icon: alertTypeIcons,
      html: data.html,
      confirmButtonText: textButton,
      customClass: {
        confirmButton: 'bg-infoSweet'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Se presionó Ok');
      }
    });
  }

  return () => { sweet }
};
