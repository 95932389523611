import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { GaranzieView } from '../interfaces/garanzie';
import { Observable, finalize } from 'rxjs';
import { formatITCurrentDate } from '@/_application/helpers/format-dates';
import { ResponseApi } from '@/_application/interfaces/response';
interface AnagraficheResponse {
  clienti: any; 
  garRefereceGUID: string;
}
@Injectable({
  providedIn: 'root'
})
export class GaranzieService extends BaseHttpGeneric<any, GaranzieView> {

  constructor() {
    super()
    this.controller = 'Garanzie'
  }

  exportReportPdf(codice: number): Observable<Blob> {
    this._loadingSvc.load()
    const url = this.handleUrl()
    return this.http.get(`${url}/Report/${codice}`, {
      responseType: 'blob'
    }).pipe(
      finalize(() => {
        this._loadingSvc.hide()
      })
    )
  }

  getAnagrafiche(codice: String): Observable<AnagraficheResponse> {
    this._loadingSvc.load()
    const url = this.handleUrl()
    return this.http.get<AnagraficheResponse>(`${url}/anagrafiche/${codice}`).pipe(
      finalize(() => {
        this._loadingSvc.hide();
      })
    );
  }

  updateHour(hour: number, codice: number, garDataPrimoAvviamento: string) {
    this._loadingSvc.load()
    const url = this.handleUrl()
    const data = { 
      oreLavorate: hour,
      garDataPrimoAvviamento
    }
    return this.http.put(`${url}/UpdateHour/${codice}`, data).pipe(
      finalize(() => {
        this._loadingSvc.hide()
      })
    )
  }
}
