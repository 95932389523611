import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { DocCatalogs, DocCatalogsView } from '../interfaces/doc-catalogs.interface';

@Injectable({
  providedIn: 'root'
})
export class DocCatalogsService extends BaseHttpGeneric<DocCatalogsView, DocCatalogs> {

  constructor() {
    super()
    this.controller = 'DocCataloghi'
   }
}
