import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { ZoneView } from '../interfaces/zone';

@Injectable({
  providedIn: 'root'
})
export class ZoneService extends BaseHttpGeneric<any, ZoneView>  {

  constructor() {
    super()
    this.controller = 'Zone'
  }
}
