<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'global.utils.profile' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['/']">{{ 'global.utils.panel' | translate }}</a></li>
                    <li class="breadcrumb-item active">{{ 'global.utils.profile' | translate }}</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <app-multi-tabs [selectedNav]="nav" [tabs]="optionsNaV"
            (selectedNavEmit)="handleSelectedNav($event)">
            <ng-container body>
                <ng-container [ngSwitch]="nav.opt">
                    <ng-container *ngSwitchCase="''">
                        <app-update-profile></app-update-profile>
                    </ng-container>
                    <ng-container *ngSwitchCase="'changePassword'">
                        <app-change-password></app-change-password>
                    </ng-container>
                </ng-container>
            </ng-container></app-multi-tabs>
    </div>
</section>
