import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Installaters } from '../interfaces/installer';

@Injectable({
  providedIn: 'root'
})
export class InstallersService extends BaseHttpGeneric<any, Installaters>  {
  private installatersFormState: any;
  constructor() {
    super()
    this.controller = 'Installatori'
  }
  saveInstallatersFormState(formState: any) {
    this.installatersFormState = formState;
  }

  getInstallatersFormState() {
    return this.installatersFormState;
  }

  clearFormInstallatersState() {
    this.installatersFormState = null;
  }
}
