<div class="form-group" [formGroup]="formGroup">
  <label [for]="nameInput" *ngIf="showLabel">{{ label | translate }}
    <span *ngIf="isRequired(formGroup.controls[nameInput])">*</span>
  </label>
  <div class="input-group mb-3">
  <input (blur)="methodWithBlur()" [max]="max" [readOnly]="read" class="form-control" (input)="clearSpacesStart($event)" [id]="nameInput"
    [formControlName]="nameInput" [maxlength]="maxLength" [minlength]="minlength" autocomplete="off"
    [placeholder]="placeholder" [type]="type" (keypress)="validateFormat($event)"/>
 
    <div class="input-group-append">
      <button type="button" class="btn btn-primary" data-toggle="modal" [disabled]="isButtonDisabled"
        data-target="#" *ngIf="showButton" (click)="onButtonClick()">{{labelButton}}</button>
    </div>
  </div>
    <ng-container *ngIf="matchNotPassword && showError">
    <div class="wrapper">
      <div class="text-danger">
        {{ pubEntity.errorPassDontMatch | translate }}
      </div>
    </div>
  </ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'required',
      message: pubEntity.errorFieldMandatory | translate,
      control: formGroup.controls[nameInput]
    }"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'email',
      message: pubEntity.errorEmailInvalid | translate,
      control: formGroup.controls[nameInput]
    }"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'minLength',
      message: (pubEntity.errorAtLeast | translate) + minlength + (pubEntity.errorSymbols | translate),
      control: formGroup.controls[nameInput]
    }"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
    validation: 'phone',
    message: pubEntity.errorOnlyNumbers | translate,
    control: formGroup.controls[nameInput]
  }"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
    validation: 'phoneLength',
    message: pubEntity.errorAtLeast6Numbers | translate,
    control: formGroup.controls[nameInput]
  }"></ng-container>

  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
  validation: 'fax',
  message: pubEntity.errorFaxOnlyNumbers | translate,
  control: formGroup.controls[nameInput]
}"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
  validation: 'faxLength',
  message: pubEntity.errorFaxAtLeast6Numbers | translate,
  control: formGroup.controls[nameInput]
}"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'maxlength',
      message: pubEntity.errorMaximumCharacters | translate,
      control: formGroup.controls[nameInput]
    }"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'pattern',
      message: pubEntity.errorInvalidFormat | translate,
      control: formGroup.controls[nameInput]
    }"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
    validation: 'onlyNumbers',
    message: pubEntity.errorOnlyAllowsNumbers | translate,
    control: formGroup.controls[nameInput]
  }"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
  validation: 'url',
  message: pubEntity.errorWrongWebFormat | translate,
  control: formGroup.controls[nameInput]
}"></ng-container>
  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
  validation: 'dateMin',
  message: pubEntity.errorDateAllowed | translate,
  control: formGroup.controls[nameInput]
}"></ng-container>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="showError && control && control.hasError(validation) && !matchNotPassword">
    <div class="wrapper">
      <div class="text-danger">
        {{ message }}
      </div>
    </div>
  </ng-container>
</ng-template>
