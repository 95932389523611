import { selectEntity } from '@/_application/config/genericEntity';
import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomerService } from '@modules/customers/services/customer.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss']
})
export class SelectCustomerComponent  extends HelperSelect{
  @Input() showError: boolean = false;
  @Input() control: any;
  pubEntity = selectEntity

  constructor(private _patternSvc: CustomerService) {
    super()
  }
  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(resp => {
        const { data } = resp

        const options = data.map((elem): OptionsSelect => {
          const { codice, cliRagioneSociale } = elem
          return {
            label: cliRagioneSociale,
            value: this.codeView ? elem[this.codeView] : codice,
          }
        })

        this.rows = options
      })
  }

}
