<app-form-base [isSubmitted]="isSubmitted" [isDeleted]="isDelete" [entity]="entity" [form]="form"
    (submitData)="handleSubmited()" *ngIf="loadForm" (deletedData)="handleDelete()" (cancelData)="handleCancel()">
    <ng-container body>
        <div class="row">
            <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.firstName" nameInput="firstName"
                [formGroup]="form" [showError]="hasError" maxLength="100"></app-input-generic>
            <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.lastName" nameInput="lastName"
                [formGroup]="form" [showError]="hasError" maxLength="100"></app-input-generic>
            <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.email" nameInput="email"
                [formGroup]="form" [showError]="hasError" maxLength="150"></app-input-generic>
            <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.roleId" nameInput="roleId"
                [formGroup]="form" [showError]="hasError" maxLength="10"></app-input-generic>
            <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.phone" nameInput="phone"
                [formGroup]="form" [showError]="hasError" maxLength="20"></app-input-generic>
            <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.mobilePhone"
                nameInput="mobilePhone" [formGroup]="form" [showError]="hasError" maxLength="20"></app-input-generic>
            <div class="col-12">
                <div class="row justify-content-center">
                    <app-image-preview (fileBase64)="onChange($event, 'profileImage')"
                        [imageBase64]="form.controls['profileImage'].value"
                        class="col-12 col-md-4 mb-2 mb-md-0"></app-image-preview>
                </div>
            </div>
        </div>
    </ng-container>

</app-form-base>
