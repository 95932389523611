import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { handleDataLanguage, handleRequestLanguage } from '@modules/helpers/helpers';
import { ILanguage, LanguageService } from '@modules/publications/services/language.service';

@Component({
  selector: 'app-select-lang',
  templateUrl: './select-lang.component.html',
  styleUrls: ['./select-lang.component.scss']
})
export class SelectLangComponent extends HelperSelect {
  @Input() showError: boolean = false;
  @Input() control: any;
  constructor(
    private _languageSvc: LanguageService
  ) {
    super();
  }
  protected handleData(search: string, query?: any): void {
    this._languageSvc.getLanguages().subscribe((res: any) => {
      const { data } = res

      const options = data.map((elem): OptionsSelect => {
        const { linNome, codice } = elem
        return {
          label: linNome,
          value: codice
        }
      })

      this.rows = options
    });
  }

}
