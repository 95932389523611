import { WrapperService } from '@/_application/services/wrapper.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';

@Component({
  selector: 'app-form-generic-step',
  templateUrl: './form-generic-step.component.html',
  styleUrls: ['./form-generic-step.component.scss']
})
export class FormGenericStepComponent implements OnInit {
  options = [
    {
      label: "global.utils.dettagli",
      value: "dettagli"
    },
    {
      label: "global.utils.contatti",
      value: "contatti"
    }
  ]
  @Input() view = "dettagli"
  @Input() isSubmitted = false
  @Input() optionsCustom = undefined
  @Input() isDeleted = false
  @Input() form!: FormGroup;
  @Output() changeView = new EventEmitter()
  public user: UserCurrent | null = null

  constructor(
    private _router: Router,
    private readonly _authSvc: AuthenticationService,
    private _wrapperSvc: WrapperService
  ) {
    this.user = this._authSvc.currentUserValue
  }

  ngOnInit(): void {
    this.editMode()
  }

  editMode() {
    const editMode = this._router.routerState.snapshot.url.includes('edit')
    this.isDeleted = editMode
    if (!this.isDeleted) {
     this.options = [this.options[0]]
    }else{
      if(this.optionsCustom){
        this.options.push(this.optionsCustom)
      }
    }
    

  }

  handleView(view: string) {
    this.changeView.emit(view)
  }

  handleDelete() { }

  handleCancel() { }

  handleSubmited() { }
}
