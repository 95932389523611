import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@modules/authentication/guards/auth.guard';
import { NonAuthGuard } from '@modules/authentication/guards/non-auth.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./modules/authentication/auth.module').then(m => m.AuthModule),
        canActivate: [NonAuthGuard],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
