<div class="row align-items-end">
  <div class="col-10 col-md-12" [ngClass]="{'col-12': !mobile}">
    <!-- 90% de ancho en dispositivos móviles, 100% de ancho en pantallas más grandes -->
    <div class="form-group">
      <app-select-generic [control]="control" [showError]="showError" [loading]="loading"
        (searchValue)="handleData($event)" label="Matricola *" [code]="code" [options]="rows"
        (changeValue)="handleChange($event)"></app-select-generic>
    </div>
  </div>
  <div class="col-2 d-md-none d-flex justify-content-end" *ngIf="mobile">
    <!-- 10% de ancho en dispositivos móviles, alineado a la derecha -->
    <div class="form-group">
      <button class="camera-button taller" (click)="openCameraOnMobile($event)">
        <i class="fas fa-camera"></i>
      </button>
    </div>
  </div>
</div>