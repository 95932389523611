import { GenericEntity } from "@/_application/config/genericEntity";

export const ContattiEntity = {
    ...GenericEntity,
    add: 'contatti.add',
    edit: 'contatti.edit',
    messageDelete: 'contatti.messageDelete',
    messageAdd: 'contatti.messageAdd',
    messageEdit: 'contatti.messageEdit',
    messageExport: 'contatti.messageExport',
    fileName: 'contatti.fileName',
    route:'/contatti',
    params: {}
}