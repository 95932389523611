import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component } from '@angular/core';
import { ZoneService } from '@modules/zone/services/zone.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-zone',
  templateUrl: './select-zone.component.html',
  styleUrls: ['./select-zone.component.scss']
})
export class SelectZoneComponent extends HelperSelect{

  constructor(private _patternSvc: ZoneService) {
    super();
  }


  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp

      const options = data.map((elem): OptionsSelect => {
        const { codice, zonNome } = elem
        return {
          label: zonNome,
          value: codice
        }
      })

      this.rows = options

    })
  }

}
