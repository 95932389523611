import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatusApp } from './_application/interfaces/global';
import { Router } from '@angular/router';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { Subscription } from 'rxjs';
import { SharedService } from './_application/services/shared.service';
import { Title } from '@angular/platform-browser';
import { LanguageChangeService } from './_application/services/language-change.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private unsubscribe: Subscription[] = [];
    statusApp: StatusApp
    loadingRouteConfig: boolean = true;
    timeout: any;
    constructor(private readonly translate: TranslateService, 
        private titleService: Title,
        private router: Router, private readonly authSvc: AuthenticationService,
        public readonly _sharedSvc: SharedService,
        private _lngChangedService: LanguageChangeService,
    ) {
        const lang = this.GetLoadLanguage()
        translate.setDefaultLang(lang);
        translate.use(lang);
        this.statusApp = this.authSvc.statusAppCurrent
    }
        
    ngOnInit(): void {
        window.onload = () => {
            if (localStorage.getItem('deletedEntity')) {
              localStorage.removeItem('deletedEntity');
            }
        };

        this.loader();
        this.verifyStatus()
        this._sharedSvc.getSettings()

        this._lngChangedService.setSelectLanguage(localStorage.getItem('language_default') || '1');
    }

    GetLoadLanguage() {
        const lang = localStorage.getItem('language_default');
        return lang || '1'
    }

    loader() {
        const loader = document.getElementById('eq-loader');
        const htmlBody = document.querySelector('html');
        loader?.classList.remove('hidden');
        htmlBody?.classList.add('overflow-hidden');
    }

    verifyStatus() {
        const sb = this.authSvc.statusApp$.subscribe((elem) => {
            if (elem === "verifed") {
                const loader = document.getElementById('eq-loader');
                const htmlBody = document.querySelector('html');
                loader?.classList.add('hidden');
                htmlBody?.classList.remove('overflow-hidden');
                this.statusApp = 'verifed';
            }
        });
        this.unsubscribe.push(sb)
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeout);
        this.unsubscribe.forEach(sb => sb.unsubscribe());
    }
}
