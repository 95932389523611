import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { NotificationEmail, NotificationEmailView } from '../interface/notification-email';

@Injectable({
  providedIn: 'root'
})
export class NotificationEmailService extends BaseHttpGeneric<NotificationEmail, NotificationEmailView> {

  constructor() {
    super()
    this.controller = 'NotificheEmail'
  }
}
