import { GenericEntity } from "@/_application/config/genericEntity";

export const UserEntity = {
    ...GenericEntity,
    add: 'Aggiungi Utente',
    edit: 'Modifica Utente',
    messageDelete: 'Utente eliminato con successo!',
    messageAdd: 'Utente Salvato con successo!',
    messageEdit: 'Utente aggiornato con successo!',
    messageExport: 'Utente esportare con successo!',
    messageUpdatePassword: 'Password aggiornata con successo',
    fileName: 'utente',
    route: '/utenti',
    form: {
        userName: 'Nome Utente *',
        firstName: 'Nome *',
        lastName: 'Cognome *',
        email: 'E-mail *',
        password: 'Password *',
        cPassword: 'Conferma Password *',
        phone: 'Telefono',
        mobilePhone: 'Cellulare',
        roleId: 'Ruolo',
        active: 'Active'
    }
}