import { FormGeneric } from '@/_application/helpers/generic/form-generic';
import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { UserService } from '@modules/users/services/user.service';
import { UserEntity } from '@pages/config/entity';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent extends FormGeneric {
  isDelete = false
  public isCancel:boolean=false;
  constructor(
    private readonly _patternSvc: UserService,
    private readonly _appSvc: AuthenticationService
  ) {
    super();
    this.entity = { ...UserEntity, route: '/' }
    this.initConfigExt = false
    this.open = false
  }


  protected initForm(): Observable<boolean | any> {
    this.isEdit = true;
    this.loadForm = false
    this.form = this._fb.group({
      firstName: [null, [Validators.required, this.validations.validateEmptyValue]],
      lastName: [null, [Validators.required, this.validations.validateEmptyValue]],
      email: [null, [Validators.required, this.validations.emailValidateNew()]],
      phone: [null, [Validators.minLength(6), Validators.maxLength(20),this.validations.phoneCustomValidate(), this.validations.phoneLengthValidate()]],
      mobilePhone: [null, [Validators.minLength(6), Validators.maxLength(20),this.validations.phoneCustomValidate(), this.validations.phoneLengthValidate()]],
      roleId: [null, [Validators.required]],
      profileImage: [""]
    })
    this.form.controls['roleId'].disable();
    this.form.controls['email'].disable();
    return of(true);
  }
  ngOnDestroy() {
    if (!this.form.pristine && !this.isCancel) {
      this._appSvc.saveProfileFormState(this.form.value);
      this.isCancel=!this.isCancel
    }
  }
  protected deleted(): void {
    throw new Error('Method not implemented.');
  }
  protected getDataForEdit(): void {

    const { userName, firstName, lastName, email, phone, userRoleDescription, mobilePhone, profileImage } = this._appSvc.currentUserValue.current
    const savedState = this._appSvc.getProfileFormState();
    if(savedState){
      this.form.patchValue(savedState);
      this.loadForm = true;
    }else{
    this.form.patchValue({
      userName,
      firstName,
      lastName,
      email,
      phone,
      mobilePhone,
      roleId: userRoleDescription,
      profileImage
    })
  }
    this.loadForm = true;
  }
  protected handleSubmit(formValues: any): void {
    this.isSubmitted = true
    const { idUser } = this._appSvc.currentUserValue.current
    const values = {
      ...formValues,
      roleId: undefined,
      email: undefined
    }
    this._patternSvc.updateProfile(values, +idUser).subscribe({
      next: (resp) => {
        this.toastr.success(resp.message);
        this._appSvc.updateProfile(values)
        this.isSubmitted = false

      },
      error: () => {
        this.isSubmitted = false

      },
    })
  }
  public handleCancel(){
    this._appSvc.clearFormProfileState();
    this.isCancel=true;
    this.form.reset({
      name: [null]
    });
  }
}
