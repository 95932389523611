import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { RoleService } from '@modules/role/services/role.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.scss']
})
export class SelectRoleComponent extends HelperSelect{
  @Input() showError: boolean = false;
  @Input() control: any;
  @Input() userTypeParam?: string | null = '';
  constructor(private _patternSvc: RoleService) {
    super();
  }
  protected handleData(search: string, query?: any): void {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
    }).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp

      console.log(data);
      console.log(this.userTypeParam);
      console.log(this.control.value);
    
      if(!this.control.value) {
        const findUserType = data.find((elem) => 
          this.areStringsSimilar(elem.name.trim().toLowerCase(), this.userTypeParam.trim().toLowerCase())
        );
        if(findUserType) {
          this.changeData.emit({
            label: findUserType.name,
            value: findUserType.id
          })
        }else {
          const defaultUser = data.find((elem) => elem.defaultRole === true)
          this.changeData.emit({
            label: defaultUser.name,
            value: defaultUser.id
          })
        }
      }

      const options = data.map((elem): OptionsSelect => {
        const { name, id } = elem
        return {
          label: name,
          value: id
        }
      })

      this.rows = options

    })
  }

  areStringsSimilar(str1, str2) {
    const commonLength = Math.min(str1.length, str2.length);
    const matchingChars = Array.from(str1).filter((char, index) => str2[index] && char === str2[index]).length;
    const similarity = matchingChars / commonLength;
  
    return similarity >= 0.6;
  }

}
