<form [formGroup]="form" (submit)="handleSubmited()" novalidate class="form-refresh">
    <div class="card-body">
        <div class="wrapper wrapper-form" [ngClass]="{'not-scroll-full':selectedNav.opt}">
            <ng-content #body> </ng-content>
        </div>
    </div>
    <!-- /.card-body -->
    <div class="card-footer d-flex" *ngIf="!selectedNav.opt || selectedNav.form">
        <button [disabled]="isSubmitted" (click)="handleDeleted()" *ngIf="isDeleted && user.current.role.canDelete"
            type="button" class="btn bg-gradient-danger mr-auto" style="margin-left:7.5px;">{{'global.utils.eliminare' | translate}}</button>
        <button [disabled]="isSubmitted" (click)="handleCancel()" [hidden]="hiddenButtonCancel" type="button"
            class="btn bg-gradient-secondary">{{'global.utils.annulla' | translate}}</button>
        <button [disabled]="isSubmittedTestEmail" (click)="handleTestConnection()" [hidden]="hiddenTestEmailBtn" type="button"
            class="btn bg-gradient-secondary">
            {{!isSubmittedTestEmail ? ('global.utils.testEmail' | translate) : ''}}
            <ng-container *ngIf="isSubmittedTestEmail">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{'global.utils.caricamento' | translate}}...
            </ng-container>
        </button>
        <button *ngIf="user.current.role.canWrite || profile" type="submit" class="btn bg-gradient-success ml-2" style="margin-right:15px;">
            {{!isSubmitted ? ('global.utils.salva' | translate) : ''}}
            <ng-container *ngIf="isSubmitted">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{'global.utils.caricamento' | translate}}...
            </ng-container>
        </button>
    </div>
</form>
