<div class="row">

  <label class="dropdown-label">{{'global.select.selectFiliale' | translate}}</label>
  <div class="col-12 pl-1 pb-3 dropdown-container" appClickOutside (clickOutside)="handleClickOutsideDrop()">
      <div class="position-relative">
          <input type="text" [formControl]="searchControl" [readOnly]="disabled" [placeholder]="pubEntity.selectAnOption | translate"  class="form-control pr-5 dropdown-multicolumn" (focus)="!disabled ? showTableFilter = true : showTableFilter = false">
          <div [hidden]="disabled">
            <span class="dropdown-close-btn" [hidden]="!searchControl.value.trim().length" (click)="clearSearch()">
              <i class="fas fa-times" style="font-size: 11px;"></i>
            </span>
            <span class="dropdown-down-btn" [hidden]="!showTableFilter" (click)="showTableFilter = !showTableFilter">
              <i class="fas fa-caret-up" style="font-size: 13px;"></i>
            </span>
            <span class="dropdown-down-btn" [hidden]="showTableFilter" (click)="showTableFilter = !showTableFilter">
              <i class="fas fa-caret-down" style="font-size: 13px;"></i>
            </span>
          </div>
      </div>

      <div [hidden]="!showTableFilter" class="mat-elevation-z8 demo-table dropdown-table">
          <div>
              <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100 dropdown-multicolumn">

                  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('filiali.form.'+column) | translate }} </th>
                    <td mat-cell *matCellDef="let element" (click)="onRowClick(element)" [class.selected]="selectedRow === element"> {{ element[column] }} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)" [class.selected]="selectedRow === row"></tr>

              </table>
              <mat-paginator [hidePageSize]="true" [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
          </div>
      </div>
  </div>

</div>
