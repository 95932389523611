import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { UserService } from '@modules/users/services/user.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent extends HelperSelect {
  @Input() query: boolean = false;
  @Input() showError: boolean = false;
  @Input() control: any;
  constructor(private _patternSvc: UserService) {
    super();
  }


  protected handleData(search: string, query?: any): void {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    },
      this.query ? { reseller: true } : undefined
    ).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp

      const options = data.map((elem): OptionsSelect => {
        const { userGuid, email, firstName, lastName } = elem
        const  nameClient = `${firstName} ${lastName}`
        return {
          label: this.query ? nameClient  : email,
          value: userGuid
        }
      })
      this.rows = options
    })
  }

}
