import { WrapperService } from '@/_application/services/wrapper.service';
import { tableEntity } from '@/_application/config/genericEntity';
import { TableProps } from '@/_application/interfaces/generic-table';
import { SendData } from '@/_application/interfaces/report';
import { ISortColumn, Metadata, SortType } from '@/_application/interfaces/response';
import { OptionsSelect } from '@/_application/interfaces/select';
import { FilterColumnService } from '@/_application/services/filter-column.service';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

const whiteList = ['cliId', 'desId', 'filId', "clienti", "matricole"]

const pubTipoMap = {
  0: { icon: 'fas fa-file-pdf', tooltipKey: 'documentation.form.pdf' },
  1: { icon: 'fas fa-file-code', tooltipKey: 'documentation.form.html' },
  2: { icon: 'fas fa-tools', tooltipKey: 'documentation.form.manutenzioni' },
  3: { icon: 'fas fa-cogs', tooltipKey: 'documentation.form.ricambi' },
  4: { icon: 'fas fa-paperclip', tooltipKey: 'documentation.form.allegati' },
  5: { icon: 'fas fa-file-alt', tooltipKey: 'documentation.form.ricambiPdf' }
};
@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss']
})
export class GenericGridComponent implements OnInit {

  @Input() entity: any
  @Input() updateTitleState?: boolean = true
  @Input() ConfigTable: TableProps
  @Input() data: any[];
  @Input() readOnly: boolean = false;
  @Input() filter: boolean = true;
  @Input() meta!: Metadata

   @Input() showSearchByMatricole: boolean = false;

   @Output() searchMatricole: EventEmitter<any> = new EventEmitter<any>();

  @Output() nextPage: EventEmitter<Metadata> = new EventEmitter<Metadata>();
  @Output() sortColumn: EventEmitter<ISortColumn> = new EventEmitter<ISortColumn>();
  @Output() filterColumn: EventEmitter<ISortColumn> = new EventEmitter<ISortColumn>();
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @Output() report: EventEmitter<SendData> = new EventEmitter<SendData>();
  @Output() handleDeleteAction: EventEmitter<any> = new EventEmitter<any>();
  pubEntity = tableEntity

  public searchValue = ""
  public column = ""
  public defaultOrder: SortType = 0
  public selectedColumn = ""
  public selectedHeader = ""
  public filterValue = ""
  public user: UserCurrent | null = null
  public showModalFilter: boolean = false
  public top = ""
  public left = ""
  public field = ""

  public lastMatricolaSearch: string = ""; 

  public matricolaSearchValue: string = "";

public clearSearch: boolean = false;

  constructor(private _wrapperSvc: WrapperService, private route: Router, private readonly _authSvc: AuthenticationService, private filterService: FilterColumnService,
    private readonly translateService: TranslateService,
      private cdr: ChangeDetectorRef


  ) {
    this.user = this._authSvc.currentUserValue
    filterService.columnsFilter = []

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleView()
  }

  ngOnInit(): void {
    if(this.updateTitleState) this._wrapperSvc.setShowTitle(true);
  }

 getPubTipoIcon(pubTipo: number) {
    const mapping = pubTipoMap[pubTipo];
    return mapping ? mapping : { icon: 'bi-question-circle', tooltipKey: 'Unknown' }; 
  } 

  goEditModel(codice: any, params?: any) {
    const { canDelete, canWrite } = this.user.current.role
    if (!canDelete && !canWrite) return
    if (!this.readOnly) {
      const query = {
        ...this.entity?.params
      }
      this.route.navigate(
        [`/${this.ConfigTable.route}/edit`, codice],
        { queryParams: { ...params, ...query } }
      )
    }
  }

  sort(field: string) {
    this.selectedColumn = field
    const result: boolean = this.column === field
    this.defaultOrder = result ? 0 : 1
    this.column = this.column === field ? "" : field

    this.sortColumn.emit({
      orderType: this.defaultOrder,
      orderBy: this.selectedColumn,
      queryColumns: this.filterService.columnsFilter
    })
    this.closeModalFilter()
  };

  filterByColumn(evt: any) {
    this.filterColumn.emit(evt)
  }

  handleView() {
    if (!this.field) return
    const item = document.getElementById(this.field)
    let { top, left, right, width } = item.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const rest = Math.round(right - width + 265)
    const itemLeft = windowWidth > rest ? `${Math.round(left) + 30}px` : `${left - (-1 * (windowWidth - rest))}px`
    this.top = `${Math.round(top) + 30}px`
    this.left = itemLeft

  }

  toggleModalFilter(field: string, header: string) {
    this.field = field
    this.handleView()

    if (this.selectedColumn === field) {
      this.showModalFilter = !this.showModalFilter;
    } else {
      this.selectedColumn = field;
      this.selectedHeader = header;
      this.showModalFilter = true;
    }
  }

  closeModalFilter() {
    this.showModalFilter = false;
    this.field = ""
  }

  searchByFilterValue() {
    this.filterColumn.emit({
      filterValue: this.filterValue,
      orderType: this.defaultOrder,
      orderBy: this.selectedColumn
    })
    this.closeModalFilter();
  }

  pageChanged(evt: Metadata) {
    this.nextPage.emit(evt);
  }

 onSearch(search: string) {
  this.searchValue = search;

  if (!search && this.matricolaSearchValue) {
    this.onSearchMatricole();
  } else {
    this.search.emit({
      search: search,
      queryColumns: [],
      orderType: this.defaultOrder,
      orderBy: this.selectedColumn,
    });

    if(this.searchValue = ""){
    this.matricolaSearchValue = "";
    this.lastMatricolaSearch = "";
      this.cdr.detectChanges()
    }
    }
}

onSearchMatricole() {
  if (this.matricolaSearchValue) {
    this.lastMatricolaSearch = this.matricolaSearchValue; 

    const modifiedSearch = `matricola : ${this.matricolaSearchValue}`;
    this.searchMatricole.emit({
      search: modifiedSearch,
      queryColumns: [],
      orderType: this.defaultOrder,
      orderBy: this.selectedColumn,
    });

  this.clearSearch = true;

  this.cdr.detectChanges();

  setTimeout(() => {
    this.clearSearch = false;
    this.cdr.detectChanges();
  });
  } else {
    this.onSearch("");
  }
}

  onResetFilters() {

  this.searchValue = "";
  this.matricolaSearchValue = "";
  this.lastMatricolaSearch = "";

  this.clearSearch = true;

  this.cdr.detectChanges();

  setTimeout(() => {
    this.clearSearch = false;
    this.onSearch("");
    this.cdr.detectChanges();
  });
}



  handleReport(info: SendData) {
    this.report.emit({
      ...info,
      columns: this.handleColunmActive(),
      queryColumns: this.filterService.columnsFilter,
      orderType: this.defaultOrder,
      orderBy: this.selectedColumn
    })
  }

  handleColunmActive(): string {
    const columns = this.ConfigTable.rows.map(({ fields }) => {
      const row = this.handleViewFiled(fields)
      return {
        exportField: this.translateService.instant(row),
        exportValue: fields,
      }
    })
    const objectDb = {
      search: this.searchValue,
      columns,
    };
    return JSON.stringify(objectDb);
  }

  handleViewFiled(field: string) {
    const { module, view } = this.ConfigTable
    let label = ""
    if (whiteList.includes(field)) {
      label = `${module}.${field}`
    } else {
      if (module === "garanzie" && whiteList.includes(field)) {
        label = `${module}.${field}`
      } else {
        label = `${module}.${view}.${field}`
      }
    }
    return label
  }

  handleLabel(value: string | OptionsSelect) {
    const { module, view } = this.ConfigTable
    if (typeof value === 'object' && value) {
      return value.label
    }
    return value
  }

  handleDelete(item: any) {
    this.handleDeleteAction.emit(item);
  }
}
