import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-file-generic',
  templateUrl: './input-file-generic.component.html',
  styleUrls: ['./input-file-generic.component.scss']
})
export class InputFileGenericComponent {
  @Output() fileBase64 = new EventEmitter<string>();
  @Output() fileObject = new EventEmitter<File | null>();
  public filename: string = "Scegli il file"
  @Input() fileValue: string | null | File = null;
  @Input() imageBase64 = '';
  @Input() label = 'Logo';


  handleChangeFile(evt: Event) {
    const input = evt.target as HTMLInputElement;
    if (!input || !input.files) return;
    const { name, type } = input.files[0];
    this.fileObject.emit(input.files[0]);
    if (!this.validateType(type)) return;
    this.filename = name;
    this.getBase64(input.files[0]).then((elem: any) => {
      this.imageBase64 = elem;
      const splitImg =  elem.split(',')
      this.fileBase64.emit(splitImg[1]);
    });
  }

  validateType(type: string) {
    console.log('validate type...');
    if (type.includes('image')) return true;
    return false;
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  handleDeleteImg() {
    this.filename = 'Por defecto';
    this.imageBase64 = '';
    this.fileObject.emit(null);
  }
}
