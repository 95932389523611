import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';

 export const typeClient: OptionsSelect[] = [
  {
    label: 'Cliente',
    value: 10
  },
  {
    label: 'Armatore',
    value: 5
  },
  {
    label: 'Distributore',
    value: 6
  },
  {
    label: 'Officina',
    value: 4
  },
  {
    label: 'Installer',
    value: 7
  }
]
@Component({
  selector: 'app-select-type-client',
  templateUrl: './select-type-client.component.html',
  styleUrls: ['./select-type-client.component.scss']
})
export class SelectTypeClientComponent extends HelperSelect {
  @Input() showError: boolean = false;
  @Input() control: any;
  @Input() readonly: boolean = false;


  constructor() {
    super();
  }
  protected handleData(search: string, query?: any): void {
    this.rows = typeClient
  }

}
