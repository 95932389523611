import { Component, ElementRef, Renderer2 } from '@angular/core';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';

import { PagedListGeneric } from '@/_application/helpers/generic/paged-list-generic';
import { DocumentationView } from '@modules/documentation/interfaces/documentation';
import { DocumentationEntity } from '@modules/documentation/config/entity';
import { ExportFormat } from '@/_application/config/config';
import { finalize } from 'rxjs';
import { SearchApi } from '@/_application/interfaces/search';
import { TableProps } from '@/_application/interfaces/generic-table';
import { ConfigTableDocumentazione } from '@modules/documentation/config/table';
import { SortType } from '@/_application/interfaces/response';
import { PublicationService } from '@modules/publications/services/publication.service';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.scss']
})
export class DocumentationPageComponent extends PagedListGeneric<DocumentationView> {

  public configTable: TableProps = ConfigTableDocumentazione;

  constructor(private el: ElementRef, private renderer: Renderer2, private readonly _pubSvc: PublicationService, private readonly _authSvc: AuthenticationService) {
    super()
    this.entity = DocumentationEntity
  }
  protected initConfig(): void {
    this._wrapperSvc.addGeneralInfo({
      title: 'menu.item.menuGestioneDocumentazione',
      path: '/documentation/add'
    })
  }
  ngAfterViewInit() {
    setTimeout(() => {

      const element = document.querySelector('#add');
      if (element) {
        this.renderer.setStyle(element, 'display', 'none');
      }
    }, 500);


  }
  ngOnDestroy() {
    const element = document.querySelector('#add');
    if (element) {
      this.renderer.removeStyle(element, 'display');
    }
  }
  

   onSearchMatricole(event: any) {
    console.log('Searching matricole:', event);
    this.list(event);
  }

  protected list(evt: SearchApi): void {
    const { userGuid, reseller } = this._authSvc.currentUserValue.current
    const query = {
      userGuid: userGuid
    }
    this.loading = true;

    this._pubSvc
      .getAllTableDoc({ ...evt, orderBy: evt.orderBy ? this.handleCap(evt.orderBy) : "" }, !reseller)
      .pipe(
        finalize(() => {

        })
      )
      .subscribe((data) => {
        const normalizedData = data.data.map(elem => {
          const { ...rest } = elem;
          const date = new Date(elem.catData).toISOString().split('T')[0]
          this.loading = false;
          return {
            ...rest,

            catData: date
          };
        });
        this.handleData({ ...data, data: normalizedData });
        this.cd.detectChanges();
      });






  }
  protected generateExcelPdf(method: ExportFormat, object: string, queryColumn?: any, orderType?: SortType, orderBy?: string): void {
    const { userGuid, reseller } = this._authSvc.currentUserValue.current
    const query = {
      userGuid: !reseller ? userGuid : null
    }
  }

  handleCap(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);

  }
}
