import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { CustomerView } from '../interfaces/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseHttpGeneric<CustomerView, any> {
  private customerFormState: any;
  constructor() {
    super()
    this.controller = 'Clienti'
  }

  saveCustomerFormState(formState: any) {
    this.customerFormState = formState;
  }

  getCustomerFormState() {
    return this.customerFormState;
  }

  clearFormCustomerState() {
    this.customerFormState = null;
  }
}
