<div class="form-group relative">
    <label>{{'global.select.selectFiliale' | translate}}</label>
    <ng-select [(ngModel)]="selectedOption" [disabled]="disabled" (ngModelChange)="getRowData($event)" [items]="dropdownOptions"
    [placeholder]="pubEntity.selectAnOption | translate" (clear)="clear()">
        <ng-template ng-option-tmp let-item="item">
            <div [ngClass]="{'table-responsive': item.header}">
                <table class="table">
                    <thead *ngIf="item.header">
                        <th *ngFor="let header of headers">
                            <div style="max-width: 10px; width: 10px">{{ header | translate }}</div>
                        </th>
                    </thead>
                    <tr *ngFor="let rowData of item.data">
                        <td class="pb-0"><div style="max-width: 10px; width: 10px; text-overflow: ellipsis;">{{rowData.filRagioneSociale}}</div></td>
                        <td class="pb-0"><div style="max-width: 10px; width: 10px; text-overflow: ellipsis;">{{rowData.filEmail}}</div></td>
                        <td class="pb-0"><div style="max-width: 10px; width: 10px; text-overflow: ellipsis;">{{rowData.filCitta}}</div></td>
                        <td class="pb-0"><div style="max-width: 10px; width: 10px; text-overflow: ellipsis;">{{rowData.filIndirizzo}}</div></td>
                    </tr>
                </table>
            </div>
        </ng-template>
    </ng-select>
</div>
