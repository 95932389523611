import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OptionsSelectNav, ValueNav } from '../../interfaces/select';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { WrapperService } from '@/_application/services/wrapper.service';

@Component({
  selector: 'app-form-generic',
  templateUrl: './form-generic.component.html',
  styleUrls: ['./form-generic.component.scss']
})
export class FormGenericComponent implements OnInit, OnDestroy {
  @Input() entity: any
  @Input() isSubmitted: boolean = false
  @Input() notTile: OptionsSelectNav[] = []
  @Input() selectedNav: ValueNav = {
    opt: '',
    form: false
  }
  @Input() form!: FormGroup;
  @Input() isDeleted = false;
  @Input() isDownload = false;
  @Input() authorize = true;
  @Input() saveData = true;

  @Output() submitData = new EventEmitter()
  @Output() deletedData = new EventEmitter()
  @Output() selectedNavEmit = new EventEmitter<ValueNav>()
  @Output() donwloadData = new EventEmitter()

  public user: UserCurrent | null = null

  constructor(
    private _router: Router,
    private readonly _authSvc: AuthenticationService,
    private _wrapperSvc: WrapperService
  ) {
    this.user = this._authSvc.currentUserValue
  }

  ngOnInit(): void {
    this.editMode()
  }

  ngOnDestroy(): void {
    this._wrapperSvc.setShowTitle(true);
  }

  handleSubmited() {
    this.submitData.emit();
  }

  editMode() {
    const editMode = this._router.routerState.snapshot.url.includes('edit')
    this.isDeleted = editMode
  }

  handleCancel() {
    this._router.navigate([this.entity.route], {
      queryParams: this.entity.params
    })
  }

  handleDeleted() {
    this.deletedData.emit();
  }

  handleSelectedNav(nav: ValueNav) {
    this._wrapperSvc.setShowTitle(false);
    this.selectedNavEmit.emit(nav);
  }

  handleDonwload() {
    this.donwloadData.emit()
  }

}
