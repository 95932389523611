import { selectEntity } from '@/_application/config/genericEntity';
import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { DocCatalogsService } from '@modules/doc-catalogs/services/doc-catalogs.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-cataloghi',
  templateUrl: './select-cataloghi.component.html',
  styleUrls: ['./select-cataloghi.component.scss']
})
export class SelectCataloghiComponent extends HelperSelect {

  @Input() showError: boolean = false;
  @Input() control: any;
  pubEntity = selectEntity

  constructor(private _patternSvc: DocCatalogsService) {
    super()
  }
  protected handleData(search: string, query?: any): void {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(resp => {
        const { data } = resp

        const options = data.map((elem): OptionsSelect => {
          const { comId, catCodice } = elem
          return {
            label: catCodice,
            value: this.codeView ? elem[this.codeView] : comId,
          }
        })

        this.rows = options
      })
  }

}
