import { errorEntity } from '@/_application/config/genericEntity';
import { Component, Input } from '@angular/core';
import { onlyCharacter } from '../input-generic/input-generic.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-textare-generic',
  templateUrl: './textare-generic.component.html',
  styleUrls: ['./textare-generic.component.scss']
})
export class TextareGenericComponent {
  @Input() label = '';
  @Input() nameInput = '';
  @Input() placeholder = '';
  @Input() showLabel = true;
  @Input() align = '';
  @Input() max = '';
  @Input() only: onlyCharacter = '';
  @Input() type = 'text';
  @Input() maxLength: string | null = null;
  @Input() minlength: string | null = null;
  @Input() formGroup!: FormGroup;
  @Input() showError: boolean = false;
  @Input() read: boolean = false;
  @Input() matchNotPassword: boolean = false;
  @Input() rows: number = 5;
  pubEntity = errorEntity

  @Input() module = '';
  @Input() view = 'form';
  textLabel = ""

  constructor() {

  }

  isRequired(control: any) {
    return control.validator && control.validator({} as FormControl) && control.validator({} as FormControl).required;
  }
  validateFormat(event) {
    if (this.only === '') return
    let key: any;
    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      key = event.keyCode;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]+$/;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }
  clearSpacesStart(event) {
    event.target.value = event.target.value.trimStart()
  }
}
