import { selectEntity } from '@/_application/config/genericEntity';
import { HelperSelect } from '@/_application/helpers/helper-select';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BranchesService } from '@modules/branches/services/branches.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-multicolumns-filiali',
  templateUrl: './select-multicolumns-filiali.component.html',
  styleUrls: ['./select-multicolumns-filiali.component.scss']
})
export class SelectMulticolumnsFilialiComponent extends HelperSelect implements OnInit {

  @Output() selectedFiliali = new EventEmitter<any>()
  @Input() defaultValue: string = ''
  pubEntity = selectEntity

  selectedOption: any = '';
  headers: string[] = [
    'filiali.form.filRagioneSociale', 'filiali.form.filEmail', 'filiali.form.filCitta', 'filiali.form.filIndirizzo'
  ]

  dropdownOptions: any;

  constructor(
    private _patternSvc: BranchesService
  ) {
    super()
  }

  ngOnInit(): void {
    if (!this.disabled) this.handleData("")
    this.disabled = !this.user.current.role.canWrite
    this.selectedOption = this.defaultValue
  }

  getRowData(data) {
    if(data) {
      this.selectedFiliali.emit({
        label: '',
        value: data.data[0].codice
      })
    }else{
      this.clear()
    }
  }

  clear() {
    this.selectedFiliali.emit(null)
  }

  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp

      const options = data.map((elem, index) => {
        const { filRagioneSociale, filEmail, filCitta, filIndirizzo } = elem

        return {
          header: index == 0,
          label:  (filRagioneSociale ? filRagioneSociale : '') + ' - ' + (filEmail ? filEmail : '') + ' - ' + ( filCitta ? filCitta : '' ) + ' - ' + ( filIndirizzo ? filIndirizzo : '' ),
          data: [ elem ]
        }
      })

      this.dropdownOptions = options
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const { filiale } = changes
    if (!filiale?.currentValue) {
      this.query = null
      this.handleData('')
      return
    }
    const value = filiale.currentValue.value
    const query = {
      filId: value
    }
    this.query = query
    this.handleData('')
  }

}
