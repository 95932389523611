import { WrapperService } from '@/_application/services/wrapper.service';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
interface OptionsHeader {
  value: string
  label: string
}
@Component({
  selector: 'app-form-generic-step-header',
  templateUrl: './form-generic-step-header.component.html',
  styleUrls: ['./form-generic-step-header.component.scss']
})
export class FormGenericStepHeaderComponent  implements OnInit {

  @HostBinding('class') class = "card-header p-0 border-bottom-0"
  @Input() optionsHeader: OptionsHeader[] = []
  @Input() viewSelected: string = ""
  @Output() changeView = new EventEmitter<string>()


  ngOnInit(): void {
  }
  handleChange(view: string) {
    this.changeView.emit(view)
  }
}
