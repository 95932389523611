import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent {
  @Output() fileObject = new EventEmitter<File | null>();
  @Output() fileBase64 = new EventEmitter<string>();

  @Input() filename: string = 'Por defecto';
  @Input() imageBase64 = '';
  @Input() label: string | null = null;
  @Input() message = 'Field required';
  @Input() showError = false;
  public user: UserCurrent | null = null

  constructor(private readonly _authSvc: AuthenticationService) {
    this.user = this._authSvc.currentUserValue
  }
  handleChangeFile(evt: Event) {
    const input = evt.target as HTMLInputElement;
    if (!input || !input.files) return;
    const { name, type } = input.files[0];
    this.fileObject.emit(input.files[0]);
    if (!this.validateType(type)) return;
    this.filename = name;
    this.getBase64(input.files[0]).then((elem: any) => {
      this.imageBase64 = elem;
      const splitImg = elem.split(',')
      this.fileBase64.emit(splitImg[1]);
    });
  }
  handleImage(img: string) {
    return `data:;base64,${img}`
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  validateType(type: string) {
    if (type.includes('image')) return true;
    return false;
  }

  handleDeleteImg() {
    const photo = document.querySelector('#photo') as any;
    this.filename = 'Por defecto';
    this.imageBase64 = '';
    this.fileObject.emit(null);
    this.fileBase64.emit(null);
    photo.value = null;
  }
}
