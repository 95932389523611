<app-form-base [isSubmitted]="isSubmitted" [entity]="entity" [form]="form" (submitData)="handleSubmited()" *ngIf="loadForm"
    (deletedData)="handleDelete()">
    <ng-container body>
        <div class="row">
            <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.password"
            type="password" nameInput="password" [formGroup]="form" [showError]="hasError"
            maxLength="100"></app-input-generic>
        <app-input-generic class="col-sm-12 col-md-6 col-lg-6" label="utenti.form.cPassword"
            type="password" nameInput="cPassword" [matchNotPassword]="form.hasError('passwordInvalid')"
            [formGroup]="form" [showError]="hasError" maxLength="100"></app-input-generic>
        </div>
    </ng-container>
</app-form-base>
