import {HelperSelect} from '@/_application/helpers/helper-select';
import {OptionsSelect} from '@/_application/interfaces/select';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {OfficineService} from '@modules/authorized-workshops/services/officine.service';
import {CustomerService} from '@modules/customers/services/customer.service';
import {DistributorsService} from '@modules/distributor/services/distributor.service';
import {InstallersService} from '@modules/installers/services/installer.service';
import {ShipownersService} from '@modules/shipowners/services/shipowners.service';

@Component({
    selector: 'app-select-anagrafiche',
    templateUrl: './select-anagrafiche.component.html',
    styleUrls: ['./select-anagrafiche.component.scss']
})
export class SelectAnagraficheComponent
    extends HelperSelect
    implements OnChanges
{
    @Input() showError: boolean = false;
    @Input() readonly: boolean = false;
    @Input() control: any;
    @Input() typeClient: number | null;

    @Input() allRows?: OptionsSelect[];
    @Output() rowsUpdated: EventEmitter<OptionsSelect[]> = new EventEmitter<
        OptionsSelect[]
    >();

    public clear = '';
    constructor(
        private readonly _customerSvc: CustomerService,
        private readonly _shipownersSvc: ShipownersService,
        private readonly _distributorsSvc: DistributorsService,
        private readonly _installersSvc: InstallersService,
        private readonly _officineSvc: OfficineService
    ) {
        super();
    }

    protected handleData(search?: string, query?: any): void {
        if (this.typeClient === null) {
            this.rows = [];
            return;
        }
        this.loading = true;
        switch (this.typeClient) {
            case 10:
                this.handleClient(search, query);
                break;
            case 5:
                this.handleArmatore(search, query);
                break;
            case 6:
                this.handleDistributore(search, query);
                break;
            case 4:
                this.handleOfficina(search, query);
                break;
            case 7:
                this.handleInstaller(search, query);
                break;

            default:
                break;
        }
    }

    handleClient(search?: string, query?: any) {
        this._customerSvc
            .getAllAsyncWhitQuery({
                ...this.default,
                search,
                loading: false
            })
            .subscribe((resp) => {
                const rows: OptionsSelect[] = resp.data.map(
                    ({cliRagioneSociale, cliGuid}) => {
                        return {
                            label: cliRagioneSociale,
                            value: cliGuid
                        };
                    }
                );

                this.rows = rows;
                this.allRows = rows;
                this.rowsUpdated.emit(this.allRows);
                this.loading = false;
            });
    }
    handleArmatore(search?: string, query?: any) {
        this._shipownersSvc
            .getAllAsyncWhitQuery({
                ...this.default,
                search,
                loading: false
            })
            .subscribe((resp) => {
                const rows: OptionsSelect[] = resp.data.map(
                    ({armNome, armCognome, armGuid}) => {
                        return {
                            label: armNome + ' ' + armCognome,
                            value: armGuid
                        };
                    }
                );
                this.rows = rows;
                this.loading = false;
            });
    }
    handleDistributore(search?: string, query?: any) {
        this._distributorsSvc
            .getAllAsyncWhitQuery({
                ...this.default,
                search,
                loading: false
            })
            .subscribe((resp) => {
                const rows: OptionsSelect[] = resp.data.map(
                    ({disRagioneSociale, disGuid}) => {
                        return {
                            label: disRagioneSociale,
                            value: disGuid
                        };
                    }
                );
                this.rows = rows;
                this.loading = false;
            });
    }
    handleOfficina(search?: string, query?: any) {
        this._officineSvc
            .getAllAsyncWhitQuery({
                ...this.default,
                search,
                loading: false
            })
            .subscribe((resp) => {
                const rows: OptionsSelect[] = resp.data.map(
                    ({offRagioneSociale, offGuid}) => {
                        return {
                            label: offRagioneSociale,
                            value: offGuid
                        };
                    }
                );
                this.rows = rows;
                this.loading = false;
            });
    }
    handleInstaller(search?: string, query?: any) {
        this._installersSvc
            .getAllAsyncWhitQuery({
                ...this.default,
                search,
                loading: false
            })
            .subscribe((resp) => {
                const rows: OptionsSelect[] = resp.data.map(
                    ({insNome, insCognome, insGuid}) => {
                        return {
                            label: insNome + ' ' + insCognome,
                            value: insGuid
                        };
                    }
                );
                this.rows = rows;
                this.loading = false;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const {typeClient, allRows} = changes;
        if (allRows && allRows.currentValue !== undefined) {
            this.handleData();
        }
        if (
            typeClient &&
            typeClient.currentValue !== undefined &&
            typeClient.currentValue !== null
        ) {
            this.typeClient = typeClient.currentValue.value;
            this.handleData();
            this.clear = this.clear === '' ? 'change' : `${this.typeClient}`;
        }
    }
}
