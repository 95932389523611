import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShipownersService extends BaseHttpGeneric<any, any> {
  private shipownerFormState: any;
  constructor() {
    super()
    this.controller = 'Armatori'
  }

  saveShipownerFormState(formState: any) {
    this.shipownerFormState = formState;
  }

  getShipownerFormState() {
    return this.shipownerFormState;
  }

  clearFormShipownerState() {
    this.shipownerFormState = null;
  }
}
