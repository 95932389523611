// export const environment = {
//     production: true,
//     captcha: '6Ld6y5cpAAAAAFFKb4XS8SbV1w0KdsY9rKRo7TEn',
//     mock: true,
//     apiUrl: 'http://95.110.133.251:7078/api',
//     newApiUrl: 'https://localhost:44364/api',
//     cookieDomain: 'localhost:7017',
//     local: {
//       apiUrl: 'https://localhost:44364/api',
//       authenticate: 'https://localhost:44317/api'
//     },
//     server: {
//       //! auth client
//       // apiUrl: 'https://api.masegenerators.ncweb.it/api',
//       // authenticate: 'https://auth.masegenerators.ncweb.it/api'

//       //! digi2
//       // apiUrl: 'http://95.110.133.251:6041/api',
//       // authenticate: 'http://95.110.133.251:6042/api'
//     },
//   };

export const environment = {
    production: true,
    captcha: '6LeCu9spAAAAAL4jPtUkGc2E2PxjL5UvNALKxcC3',
    mock: true,
    apiUrl: 'https://api.cifa.docutechsoftware.it/api',
    newApiUrl: 'https://api.cifa.docutechsoftware.it/api',
    cookieDomain: 'localhost:7017',
    local: {
        apiUrl: 'https://localhost:8080/api',
        authenticate: 'https://localhost:8081/api'
    },
    server: {
        //! auth client
        // apiUrl: 'https://api.masegenerators.ncweb.it/api',
        // authenticate: 'https://auth.masegenerators.ncweb.it/api'
        //! digi2
        apiUrl: 'https://api.cifa.docutechsoftware.it/api',
        authenticate: 'https://auth.cifa.docutechsoftware.it/api'
    }
};
