import { ExportFormat } from "@/_application/config/config";
import { SendData } from "@/_application/interfaces/report";
import { ISortColumn, Metadata, ResponseApi, SortType } from "@/_application/interfaces/response";
import { SearchApi } from "@/_application/interfaces/search";
import { ExportService } from "@/_application/services/export.service";
import { WrapperService } from "@/_application/services/wrapper.service";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
    template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class PagedListGeneric<TEntityDto> implements OnInit, OnDestroy {
    public source: TEntityDto[] = [];
    public loading: boolean = false;
    public entity: any;
    public meta: Metadata = {
        currentPage: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        pageSize: 10,
        totalPages: 1,
        totalRows: 10,
        queryColumns: [],
        orderType: 0,
        orderBy: ''
    }
    public search = ''
    public initConfigExt: boolean = true
    public bodyOver: boolean = false;

    public readonly _wrapperSvc: WrapperService = inject(WrapperService)
    public readonly _exportSvc: ExportService = inject(ExportService)
    public readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);
    public readonly toastr: ToastrService = inject(ToastrService);
    public readonly translate: TranslateService = inject(TranslateService);

    ngOnInit(): void {
        this.initConfig()
        this.getData(this.meta);
        this.handleOverflow()
    }
    ngOnDestroy(): void {
        if(this.initConfigExt) this._wrapperSvc.removeCurrent()

        if (this.bodyOver) {
            const form = document.querySelector('html')
            form.classList.remove('not-scroll')
        }
    }
    handleOverflow() {
        if (this.bodyOver) {
            const form = document.querySelector('html')
            form.classList.add('not-scroll')
        }
    }
    handleData(result: ResponseApi<TEntityDto[]>) {
        const { data, meta } = result
        this.source = data;
        this.meta = meta;
    }

    getData(evt?: any, sortProperties: ISortColumn = { orderBy: '', orderType: 0 }) {
        let meta = undefined;
        if (this.meta) {
            const { currentPage, pageSize } = this.meta;
            meta = {
                ...sortProperties,
                pageSize,
                pageNumber: currentPage,
                search: this.search
            } as any;
        }
        const params = evt ? (Object.entries(evt).length === 0 ? meta : { ...evt, ...sortProperties, search: this.search }) : meta;
        this.list(params);
    }

    changePage(evt: Metadata) {
        this.getData(evt);
    }

    sortColumn(evt: ISortColumn) {
      this.getData(this.meta, evt);
    }

    filterColumn(evt: ISortColumn) {
      this.meta.currentPage = 1;
      this.getData(this.meta, evt);
    }

    onSearch(value: any) {
        const {  queryColumns, orderBy, orderType } = value;
        this.meta = {
            ...this.meta,
            queryColumns,
            currentPage: 1,
        };
        let sortPropieties = {
            orderType,
            orderBy,
        }
        this.search = value.search
        this.getData(this.meta, sortPropieties);
    }

    generatedFile(action: SendData) {
        const { type, columns, queryColumns, orderBy, orderType } = action;
        const method = {
            excel: ExportFormat.Excel,
            pdf: ExportFormat.Pdf,
        };
        this.generateExcelPdf(method[type], columns, queryColumns,  orderType, orderBy);
    }

    saveFile(data: any, method: ExportFormat) {
        this._exportSvc.generatePdf(data, this.entity.fileName, method);
        this.toastr.success(this.translate.instant(this.entity.messageExport));
    }

    protected abstract initConfig(): void;
    protected abstract list(evt: SearchApi): void;
    protected abstract generateExcelPdf(method: ExportFormat, object: string, queryColumn?: any, orderType?: SortType, orderBy?: string ): void;
}
