import { errorEntity } from '@/_application/config/genericEntity';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent { 
  @Input() label = '';
  @Input() nameInput = '';
  @Input() showLabel = true;
  @Input() showError: boolean = false;
  @Input() formGroup!: FormGroup;
  @Output() fileSelected = new EventEmitter<File>();
  @Input() selectedFileName: string = '';
  @Input() accept: string = '.json';
  pubEntity = errorEntity;

  public user: UserCurrent | null = null

  constructor(private readonly _authSvc: AuthenticationService) {
      this.user = this._authSvc.currentUserValue;
      this.selectedFileName = '';
  }

  updateFileName(event: any) {
      const files = event.target.files;
      if (files.length > 0) {
          const selectedFile = files[0];
          this.selectedFileName = selectedFile.name; 
          this.fileSelected.emit(selectedFile);
      }
  }

  validateType(type: string) {
      if (type.includes('json')) return true;
      return false;
  }


}
