<div class="col-sm-12 col-md-5 pagination-container">
    <div class="dataTables_info" role="status" aria-live="polite">{{'global.table.mostrandoDa' | translate}}
        {{meta.currentPage === 1 ? 1 :
        (meta.pageSize * (meta.currentPage - 1)) + 1}} {{'global.table.mostrandoA' | translate}} {{meta.hasNextPage ? meta.pageSize * meta.currentPage :
        meta.totalRows}} {{'global.table.di' | translate}} {{meta.totalRows}} {{'global.table.voci' | translate}}
    </div>
</div>
<div class="col-sm-12 col-md-7 pagination-container" style="overflow-x: auto;">
    <div class="paginate">
        <ul class="pagination">
            <li class="paginate_button cursor-pointer page-item previous" [ngClass]="{'disabled' : !meta?.hasPreviousPage}"><a
                    (click)="previus()" class="page-link">{{'global.table.precedente' | translate}}</a></li>
            <li *ngFor="let opt of handleLinkPages()" [ngClass]="{'active' : meta?.currentPage === opt}"
                class="paginate_button page-item">
                <a role="button" (click)="changePageLink(opt)" class="page-link">{{opt}}</a>
            </li>
            <li class="paginate_button cursor-pointer page-item next" [ngClass]="{'disabled' : !meta?.hasNextPage}" (click)="next()"><a
                    class="page-link">{{'global.table.prossimo' | translate}}</a>
            </li>
        </ul>
    </div>
</div>
