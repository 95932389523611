import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelectDropdown } from '@/_application/interfaces/select';
import { AgentsDropdown } from '@modules/agents/interfaces/agents';
import { AgentsService } from '@modules/agents/services/agents.service';
import { selectEntity } from '@/_application/config/genericEntity';
import { pubEntity } from '@modules/spare-parts/config/entity';

@Component({
  selector: 'app-select-multicolumns2-agenti',
  templateUrl: './select-multicolumns2-agenti.component.html',
  styleUrls: ['./select-multicolumns2-agenti.component.scss']
})
export class SelectMulticolumns2AgentiComponent extends HelperSelect implements OnInit {

  @Output() selectedAgent = new EventEmitter<OptionsSelectDropdown>()
  @Input() defaultValue: OptionsSelectDropdown | null = null
  @Input() filiale: OptionsSelectDropdown | null | undefined | 0 = null

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  showTableFilter: boolean = false;
  data: AgentsDropdown[];
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  selectedRow: AgentsDropdown | null
  searchControl: FormControl = new FormControl<string>('');
  pubEntity = selectEntity
  ricEntity = pubEntity

  constructor(
    private _patternSvc: AgentsService
  ) {
    super()
  }

  ngOnInit(): void {
    if (!this.disabled) this.handleData("")
    this.disabled = !this.user.current.role.canWrite
    this.searchControl.setValue(this.defaultValue ? this.defaultValue.label : '')

    this.displayedColumns = ['ageRagioneSociale', 'ageEmail', 'ageCitta', 'ageIndirizzo', 'ageZone'];


    this.searchControl.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged()
      )
      .subscribe(searchTerm => {
        const filter = searchTerm ? searchTerm : ''
        this.applyFilter(filter);
      });
  }

  applyFilter(searchTerm: string): void {
    searchTerm = searchTerm.trim().toLowerCase();
    if(this.dataSource) {
      this.dataSource.filter = searchTerm;
    }
  }

  clearSearch() {
    this.searchControl.setValue('')
    this.selectedRow = null
    this.selectedAgent.emit(null)
  }

  onRowClick(row: any): void {
    this.searchControl.setValue(row.filterName)
    this.selectedRow = row;
    this.showTableFilter = false;
    this.selectedAgent.emit({
      label: '',
      value: row.codice
    })
  }

  handleClickOutsideDrop(): void {
    this.showTableFilter = false
  }

  protected handleData(search: string) {
    if(this.filiale === null){
      this.query = {}
    }else if(this.filiale == 0) {
      this.query = {
        filId: 0
      }
    }else {
      const query = {
        filId: this.filiale.value
      }
      this.query = query
    }

    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }, {
      ...this.query,
    }).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(resp => {
        const { data } = resp

        const options = data.map((elem, index) => {
          const { ageRagioneSociale, ageEmail, ageCitta, ageIndirizzo, codice,ageZone } = elem
          const filterName = (ageRagioneSociale ? ageRagioneSociale : '') + ' - ' + (ageEmail ? ageEmail : '') + ' - ' + ( ageCitta ? ageCitta : '' ) + ' - ' + ( ageIndirizzo ? ageIndirizzo : '' ) + ' - ' + ( ageZone ? ageZone : '' )

          return { ageRagioneSociale, ageEmail, ageCitta, ageIndirizzo,ageZone, filterName, codice }
        })

        this.data = options
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        if(data.length === 0) {
          this.dataSource.data = [];
          this.searchControl.setValue('')
          this.selectedRow = null
          this.selectedAgent.emit(null)
        }
      })
  }

  counter: number = 0;
  ngOnChanges(changes: SimpleChanges) {
    this.counter++
    const { filiale } = changes
    if(this.counter%2 === 0 || !filiale?.currentValue) {
      if(filiale) {
        console.log(filiale);
        if(filiale.previousValue !== undefined) {
          this.searchControl.setValue('')
          this.selectedRow = null
          this.selectedAgent.emit(null)
          if (!filiale?.currentValue) {
            console.log('clear info...');

            this.data = []
            if(this.dataSource) this.dataSource.data = [];

            this.handleData('')
            return
          }
          const filId = filiale.currentValue.value
          const query = {
            filId
          }
          this.query = query
          this.handleData('')
        }
      }
    }
  }

}
