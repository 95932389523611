import { ResponseApi } from '@/_application/interfaces/response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ILanguage {
  pubId: number;
  codice: number;
  linNome: string;
  linLCID: number;
  linId?: number;
  icon?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public controller = "Lingue"

  currentLanguages = new BehaviorSubject<ILanguage[]>([]);
  currentLanguages$ = this.currentLanguages.asObservable();

  constructor(
    private _http: HttpClient
  ) { }

  setCurrentLanguages(languages: ILanguage[]) {
    this.currentLanguages.next(languages);
  }

  getCurrentLanguages() {
    return this.currentLanguages.value
  }

  getLanguages(): Observable<ResponseApi<ILanguage[]>> {
    return this._http.get<ResponseApi<ILanguage[]>>(`${this.handleUrl()}/`);
  }

  handleUrl(auth = false) {
    const { server, local, production } = environment
    if (production) {
      const { authenticate, apiUrl } = server
      const url = auth ? authenticate : apiUrl
      return `${url}/${this.controller}`
    }
    const { authenticate, apiUrl } = local
    const url = auth ? authenticate : apiUrl
    return `${url}/${this.controller}`
  }

}
