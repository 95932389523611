import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, catchError, finalize, map, Observable, of } from 'rxjs';
import { LoadingService } from './loading.service';
import { PublicationAvariableView } from '@modules/publications/interface/publication';

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  apiUrl: string = 'Json'
  ricambiData = new BehaviorSubject<any>(null);
  ricambiData$ = this.ricambiData.asObservable();

  manutenzioneData = new BehaviorSubject<any>(null);
  manutenzioneData$ = this.manutenzioneData.asObservable();

  currentPath = new BehaviorSubject<string>('');
  currentPath$ = this.currentPath.asObservable();

  publication = new BehaviorSubject<PublicationAvariableView>(null);
  publication$ = this.publication.asObservable();

  langCurrent = new BehaviorSubject<any>(null);
  langCurrent$ = this.langCurrent.asObservable();

  showDefaultImg = new BehaviorSubject<any>(null);
  showDefaultImg$ = this.showDefaultImg.asObservable();

  constructor(
    private _http: HttpClient,
    private _loadingSvc: LoadingService
  ) { }

  getCurrentPath() {
    return this.currentPath.value
  }

  setCurrentPath(path: string) {
    this.currentPath.next(path)
  }

  getShowDefaultImg() {
    return this.showDefaultImg.value
  }

  setShowDefaultImg(value: boolean) {
    this.showDefaultImg.next(value)
  }

  get publicationCurrent() {
    return this.publication.value
  }

  get languageCurrentValue(){
    return this.langCurrent.value
  }

  handlePublication(data: any) {
    this.publication.next(data)
  }

  removePublication(data: any) {
    this.publication.next(undefined)
  }

  getJson(name: string, linID?: number) {
    const url = this.handleUrl()
    this._loadingSvc.load()
    return this._http.get<any>(`${url}/${this.apiUrl}`, { params: { name, linID } }).pipe(
      finalize(() => {
        this._loadingSvc.hide()
      })
    )
  }
  
  fileExists(name: string, linID?: number) {
    const url = this.handleUrl()
    //  return this._http.get(`${url}/${this.apiUrl}/${name}`, {responseType: 'blob'}).pipe(
      return this._http.get<any>(`${url}/${this.apiUrl}`, { params: { name, linID } }).pipe(
      map(() => true),  
      finalize(() => of(false)) 
    );
  }

  getJsonById(id: number) {
    const url = this.handleUrl()
    this._loadingSvc.load()
    return this._http.get(`${url}/${this.apiUrl}/${id}`).pipe(
      finalize(() => {
        this._loadingSvc.hide()
      })
    )
  }

  getJsonImage(name: string) {
    const url = this.handleUrl()
    this._loadingSvc.load()
    return this._http.get(`${url}/${this.apiUrl}/images`, { responseType: 'text', params: { name } }).pipe(
      finalize(() => {
        this._loadingSvc.hide()
      })
    )
  }

  downloadJson(name: string): Observable<any> {
    this._loadingSvc.load()
    const url = this.handleUrl()

    const queryParams = {
      name
    };

    return this._http.get(`${url}/Json/download`, {
      params: queryParams,
      responseType: 'blob'
    }).pipe(
      finalize(() => {
        this._loadingSvc.hide()
      })
    )
  }

  setRicambiData(data: any) {
    this.ricambiData.next(data)
  }

  handleLang(data:any){
    this.langCurrent.next(data)
  }
  getRicambiData() {
    return this.ricambiData.value
  }

  setManutenzioneData(data: any) {
    this.manutenzioneData.next(data)
  }

  getManutenzioneData() {
    return this.manutenzioneData.value
  }

  handleUrl(auth = false) {
    const { server, local, production } = environment
    if (production) {
      const { authenticate, apiUrl } = server
      const url = auth ? authenticate : apiUrl
      return `${url}`
    }
    const { authenticate, apiUrl } = local
    const url = auth ? authenticate : apiUrl
    return `${url}`
  }

  getJsonImgUrl(normalizedPath: string) {
    let url = ''

    if (environment.production) {
      url = environment.server.apiUrl + '/Json/images?name=' + normalizedPath;
    } else {
      url = environment.local.apiUrl + '/Json/images?name=' + normalizedPath;
    }

    return url
  }

}
