import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { UserInsert } from '../interfaces/user';
import { ResponseApi } from '@/_application/interfaces/response';
import { Observable, finalize } from 'rxjs';

interface ILinID {
  linID: number
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpGeneric<UserInsert, any> {

  private userFormState: any;
  constructor() {
    super()
    this.controller = 'User'
  }

  changePassword(data: any, id: number) {
    this.controller = "User"
    const url = this.handleUrl()
    return this.http.put<ResponseApi<Date>>(
      `${url}/ChangePassword/${id}`,
      data
    );
  }

  updateLanguage(lang: ILinID, id: number): Observable<any> {
    const url = this.handleUrl()
    this._loadingSvc.load()
    return this.http.put<ResponseApi<Date>>(
      `${url}/ChangeLanguage/${id}`,
      lang
    ).pipe(
      finalize(() => {
        this._loadingSvc.hide()
      })
    );
  }

  updateProfile(data: any, id: number) {
    this.controller = "Authenticate"
    const url = this.handleUrl(true)
    this.controller = "User"
    return this.http.put<ResponseApi<Date>>(
      `${url}/UpdateProfile/${id}`,
      data
    );
  }

  updateResellerAsync(
    data: any,
    id: number,
  ): Observable<ResponseApi<any>> {
    const url = this.handleUrl()
    const normalizedData = this.validateEmptyData(data)
    return this.http.put<ResponseApi<any>>(
      `${url}/Reseller/${id}`,
      normalizedData
    );
  }

  saveUserFormState(formState: any) {
    this.userFormState = formState;
  }

  getUserFormState() {
    return this.userFormState;
  }

  clearFormUserState() {
    this.userFormState = null;
  }

}
