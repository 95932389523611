import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input, OnChanges, SimpleChanges, Pipe } from '@angular/core';
import { LanguageService } from '@modules/administration/language/services/language.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-select-lang-lcid',
  templateUrl: './select-lang-lcid.component.html',
  styleUrls: ['./select-lang-lcid.component.scss']
})
export class SelectLangLcidComponent extends HelperSelect {
  @Input() showError: boolean = false;
  @Input() control: any;
  private nameSubject = new Subject<string>();
  constructor(
    private _languageSvc: LanguageService
  ) {
    super();

    this.nameSubject.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      console.log('clear data');
      this.control.setValue(null);
      this._languageSvc.setClearSelectedValue(true);
      setTimeout(() => {
        this._languageSvc.setClearSelectedValue(false);
      }, 100);
      this.handleData(value);
    });
  }
  protected handleData(search: string, query?: any): void {
    this._languageSvc.getWithName('')
    .subscribe((res: any) => {
      const { data } = res
      console.log(this.code);
     
      const options = data.map((elem): OptionsSelect => {
        const { englishName, lcid } = elem
        return {
          label: `${englishName}`,
          value: `${englishName} -- ${lcid}`,
        }
      })

      this.rows = options
      this.code = options.find(x => x.label === this.control.value);
      this.changeData.emit(this.code)
    });
  }

}
