import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GenericGridComponent } from './table-generic/generic-grid/generic-grid.component';
import { GenericHeaderComponent } from './table-generic/generic-header/generic-header.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormGenericComponent } from './form-generic/form-generic.component';
import { InputGenericComponent } from './input-generic/input-generic.component';
import { CheckboxGenericComponent } from './checkbox-generic/checkbox-generic.component';
import { SelectAgentiComponent } from './select/select-agenti/select-agenti.component';
import { SelectFilialiComponent } from './select/select-filiali/select-filiali.component';
import { SelectNazioniComponent } from './select/select-nazioni/select-nazioni.component';
import { SelectGenericComponent } from './select-generic/select-generic.component';
import { SelectZoneComponent } from './select/select-zone/select-zone.component';
import { GenericPaginationComponent } from './table-generic/generic-pagination/generic-pagination.component';
import { ProfabricComponentsModule } from "@profabric/angular-components";
import { InputFileGenericComponent } from './input-file-generic/input-file-generic.component';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SelectCustomerComponent } from './select/select-customer/select-customer.component';
import { SelectDestinatariComponent } from './select/select-destinatari/select-destinatari.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { SelectMatricolaComponent } from './select/select-matricola/select-matricola.component';
import { SelectCataloghiComponent } from './select/select-cataloghi/select-cataloghi.component';
import { SelectRoleComponent } from './select/select-role/select-role.component';
import { MultiTabsComponent } from './multi-tabs/multi-tabs.component';
import { FormBaseComponent } from './form-base/form-base.component';
import { SelectMulticolumnsAgentiComponent } from './select/select-multicolumns-agenti/select-multicolumns-agenti.component';
import { SelectMulticolumnsFilialiComponent } from './select/select-multicolumns-filiali/select-multicolumns-filiali.component';
import { SelectMulticolumns2AgentiComponent } from './select/select-multicolumns2-agenti/select-multicolumns2-agenti.component';
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from '@angular/material/paginator';
import { ClickOutsideDirective } from "../directives/click-outside.directive";
import { SelectMulticolumns2FilialiComponent } from './select/select-multicolumns2-filiali/select-multicolumns2-filiali.component';
import { SelectModuleComponent } from './select/select-module/select-module.component';
import { GenericRowHeaderComponent } from './table-generic/generic-row-header/generic-row-header.component';
import { SelectUserComponent } from './select/select-user/select-user.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectLangComponent } from './select/select-lang/select-lang.component';
import { SelectDefaultLangComponent } from "./select/select-default-lang/select-default-lang.component";
import { CheckboxGenericCustomComponent } from "./checkbox-generic-custom/checkbox-generic-custom.component";
import { InputEditorTextGenericComponent } from "./input-editor-text/input-generic-editor-text.component";
import { NgxSummernoteModule } from 'ngx-summernote';
import { InputFileComponent } from "./input-file/input-file.component";
import { InputImageComponent } from "./input-image/input-image.component";
import { TextareGenericComponent } from './textare-generic/textare-generic.component';
import { SelectTypeClientComponent } from './select/select-type-client/select-type-client.component';
import { SelectAnagraficheComponent } from './select/select-anagrafiche/select-anagrafiche.component';
import { FormGenericStepComponent } from './form-generic-step/form-generic-step.component';
import { FormGenericStepHeaderComponent } from './form-generic-step-header/form-generic-step-header.component';
import { FormGenericContattiComponent } from './form-generic-contatti/form-generic-contatti.component';
import { ImagePreviewUploadComponent } from './image-preview-upload/image-preview-upload.component';
import { SelectLangLcidComponent } from './select/select-lang-lcid/select-lang-lcid.component';
import { InfoItemComponent } from "@modules/home/components/info-item/info-item.component";
import { ModalUpdateHourComponent } from "@modules/home/components/modal-update-hour/modal-update-hour.component";
import { ModalGenericComponent } from "./modal-generic/modal-generic.component";
import { SelectTypeSerialNumberComponent } from "./select/select-type-serial-number/select-type-serial-number.component";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { UiSwitchModule } from "ngx-toggle-switch";
@NgModule({
    declarations: [
        GenericGridComponent,
        GenericHeaderComponent,
        FormGenericComponent,
        InputGenericComponent,
        InputFileComponent,
        InputImageComponent,
        InputEditorTextGenericComponent,
        CheckboxGenericComponent,
        CheckboxGenericCustomComponent,
        SelectAgentiComponent,
        SelectFilialiComponent,
        SelectNazioniComponent,
        SelectGenericComponent,
        SelectZoneComponent,
        GenericPaginationComponent,
        InputFileGenericComponent,
        ImagePreviewComponent,
        ImagePreviewUploadComponent,
        SelectCustomerComponent,
        SelectDestinatariComponent,
        SelectMatricolaComponent,
        SelectCataloghiComponent,
        SelectRoleComponent,
        MultiTabsComponent,
        FormBaseComponent,
        SelectMulticolumnsAgentiComponent,
        SelectMulticolumnsFilialiComponent,
        SelectMulticolumns2AgentiComponent,
        ClickOutsideDirective,
        SelectMulticolumns2FilialiComponent,
        SelectModuleComponent,
        GenericRowHeaderComponent,
        SelectUserComponent,
        SelectLangComponent,
        SelectDefaultLangComponent,
        TextareGenericComponent,
        SelectTypeClientComponent,
        SelectAnagraficheComponent,
        FormGenericStepComponent,
        FormGenericStepHeaderComponent,
        FormGenericContattiComponent,
        SelectLangLcidComponent,
        InfoItemComponent,
        ModalUpdateHourComponent,
        SelectTypeSerialNumberComponent
    ],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProfabricComponentsModule,
        AutocompleteLibModule,
        NgSelectModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        TranslateModule,
        NgxSummernoteModule,
        ModalGenericComponent,
        TooltipModule.forRoot(),
        UiSwitchModule
    ],
    exports: [
        GenericGridComponent,
        FormGenericComponent,
        InputGenericComponent,
        InputFileComponent,
        InputImageComponent,
        InputEditorTextGenericComponent,
        CheckboxGenericComponent,
        CheckboxGenericCustomComponent,
        SelectAgentiComponent,
        SelectFilialiComponent,
        SelectNazioniComponent,
        SelectZoneComponent,
        InputFileGenericComponent,
        ImagePreviewComponent,
        ImagePreviewUploadComponent,
        SelectCustomerComponent,
        SelectDestinatariComponent,
        SelectMatricolaComponent,
        SelectCataloghiComponent,
        SelectRoleComponent,
        MultiTabsComponent,
        SelectMulticolumnsAgentiComponent,
        SelectMulticolumnsFilialiComponent,
        SelectMulticolumns2AgentiComponent,
        SelectMulticolumns2FilialiComponent,
        SelectLangComponent,
        SelectDefaultLangComponent,
        FormBaseComponent,
        SelectModuleComponent,
        SelectUserComponent,
        ClickOutsideDirective,
        TextareGenericComponent,
        SelectTypeClientComponent,
        SelectAnagraficheComponent,
        FormGenericStepComponent,
        FormGenericContattiComponent,
        SelectLangLcidComponent,
        InfoItemComponent,
        ModalUpdateHourComponent,
        GenericPaginationComponent,
        SelectTypeSerialNumberComponent,
    ]

})

export class CustomComponentModule { }
