import { errorEntity, selectEntity } from '@/_application/config/genericEntity';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LanguageService } from '@modules/administration/language/services/language.service';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Subscription } from 'rxjs';

export type statusCombox = 'reset' | '' | 'clear'
@Component({
  selector: 'app-select-generic',
  templateUrl: './select-generic.component.html',
  styleUrls: ['./select-generic.component.scss']
})
export class SelectGenericComponent implements OnInit, OnChanges, OnDestroy {
  @Input() code: any = ""
  @Input() clearSelect: any = ""
  @Input() status: statusCombox = ""
  pubEntity = errorEntity
  selEntity = selectEntity

  @Input() showError: boolean = false;
  @Input() control!: any;
  @Input() hasCallToApi: boolean = true

  @Input() initial: OptionsSelect | null = null
  @Input() options: OptionsSelect[] = []
  @Input() label: string = 'Select'
  @Input() placeholder = this.selEntity.selectAnOption
  @Input() loading: boolean = false
  @Input() disabled: boolean = false
  @Input() required: boolean = false
  @Input() readonly: boolean = false
  keyword = 'label';
  @Output() changeValue = new EventEmitter<OptionsSelect>()
  @Output() searchValue = new EventEmitter<string>()
  public user: UserCurrent | null = null
  clearSubs = new Subscription();

  constructor(
    private readonly _authSvc: AuthenticationService,
    private _languageSvc: LanguageService
  ) {
    this.user = this._authSvc.currentUserValue
  }
  ngOnDestroy(): void {
    this.clearSubs.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearSelect) {
      const value = changes.clearSelect.currentValue
      if (value === undefined || value === null) return
      if (this.ngSelectComponent) {
        this.ngSelectComponent.handleClearClick();
      }
    }
  }
  ngOnInit(): void {
    const { canWrite } = this.user.current.role
    this.disabled = !canWrite

    this.clearSubs = this._languageSvc.clearSelectEvent$.subscribe((value) => {
      if(value) {
        console.log('clear select...');
        this.ngSelectComponent.clearModel();
      }
    })
  }

  @ViewChild('autocomplete') autocomplete;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  onchange(evt: any) {
    this.changeValue.emit(evt)
  }

  selectEvent(item: OptionsSelect) {
    this.changeValue.emit(item)
  }

  onChangeSearch(val: any) {
    if (!val) {
      this.clear()
      return
    }

    this.searchValue.emit(val.term)

  }

  searchFull(e) {
    if (this.loading) return
    e.stopPropagation();
    this.autocomplete.open();
  }

  clear() {
    this.code = { value: 'resttsts', label: 'test' }
    if (this.hasCallToApi) this.options = []
    this.changeValue.emit(null)
    this.searchValue.emit("")
  }

  selectedCar: number;
}
