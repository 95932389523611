import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appScrollEnd]',
})
export class ScrollEndDirective {
  @Output() scrollEnd = new EventEmitter<void>();

  constructor(private el: ElementRef) {
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const element = this.el.nativeElement;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
      this.scrollEnd.emit();
    }
  }
}