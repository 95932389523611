export const GenericEntity = {
    updateAt: 'Aggiorna a',
    cancel: 'Annulla',
    save: 'Salva',
    active: 'Attiva',
    delete: 'Eliminare',
    download: 'Scarica il pdf'
}

const rootError = 'error.form.';
const rootTable = 'global.table.';
const rootSelect = 'global.select.';

const generateError = (prop: string) => rootError + prop;
const generateTable = (prop: string) => rootTable + prop;
const generateSelect = (prop: string) => rootSelect + prop;

export const errorEntity = {
  errorPassDontMatch: generateError('errorPassDontMatch'),
  errorFieldMandatory: generateError('errorFieldMandatory'),
  errorEmailInvalid: generateError('errorEmailInvalid'),
  errorAtLeast: generateError('errorAtLeast'),
  errorSymbols: generateError('errorSymbols'),
  errorOnlyNumbers: generateError('errorOnlyNumbers'),
  errorAtLeast6Numbers: generateError('errorAtLeast6Numbers'),
  errorFaxOnlyNumbers: generateError('errorFaxOnlyNumbers'),
  errorFaxAtLeast6Numbers: generateError('errorFaxAtLeast6Numbers'),
  errorMaximumCharacters: generateError('errorMaximumCharacters'),
  errorInvalidFormat: generateError('errorInvalidFormat'),
  errorOnlyAllowsNumbers: generateError('errorOnlyAllowsNumbers'),
  errorWrongWebFormat: generateError('errorWrongWebFormat'),
  errorDateAllowed: generateError('errorDateAllowed'),
  errorNoArticlesFound: generateError('errorNoArticlesFound'),
};

export const selectEntity = {
  selectAnOption: generateSelect('selectAnOption'),
  selectCatalogue: generateSelect('selectCatalogue'),
  selectAgente: generateSelect('selectAgente'),
  selectCliente: generateSelect('selectCliente'),
  selectDestinatari: generateSelect('selectDestinatari'),
  selectFiliale: generateSelect('selectFiliale'),
  selectLoading: generateSelect('selectLoading'),
};

export const tableEntity = {
  tableActions: generateTable('tableActions'),
  tableNoItemsToShow: generateTable('tableNoItemsToShow'),
  tableSearchBy: generateTable('tableSearchBy'),
  tableFilter: generateTable('tableFilter'),
  tableDelete: generateTable('tableDelete'),
  tableSearch: generateTable('tableSearch'),
};
