import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { IPubManual, ResponsePublication } from '../interface/publication';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicationService extends BaseHttpGeneric<any, any>  {

  selectedPubLanguages = new BehaviorSubject<IPubManual>(null);
  selectedPubLanguages$ = this.selectedPubLanguages.asObservable();

  private documentsData = new BehaviorSubject<ResponsePublication>({} as ResponsePublication);
  public documentsData$ = this.documentsData.asObservable();

  constructor() {
    super()
    this.controller = 'Pubblicazioni'
  }

  setSelectPublicationLanguages(pub: IPubManual) {
    this.selectedPubLanguages.next(pub);
  }

  getSelectPublicationLanguages() {
    return this.selectedPubLanguages.value;
  }

  setDocuments(documentsData: ResponsePublication) {
    this.documentsData.next(documentsData);
  }
  getDocuments() {
    return this.documentsData;
  }

}
