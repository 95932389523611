const rootEntity = 'ricambi.form.';

const generateProp = (prop: string) => rootEntity + prop;

export const pubEntity = {
  ricCatalog: generateProp('ricCatalog'),
  ricDistinct: generateProp('ricDistinct'),
  ricSearch: generateProp('ricSearch'),
  ricCart: generateProp('ricCart'),
  ricDownload: generateProp('ricDownload'),

  ricPosition: generateProp('ricPosition'),
  ricCode: generateProp('ricCode'),
  ricDescription: generateProp('ricDescription'),
  ricQty: generateProp('ricQty'),
  ricTable: generateProp('ricTable'),

  ricItem: generateProp('ricItem'),
  ricAttachments: generateProp('ricAttachments'),
  ricAmount: generateProp('ricAmount'),
  ricAddToCart: generateProp('ricAddToCart'),
  ricArticle: generateProp('ricArticle'),

  ricNote: generateProp('ricNote'),
  ricConfirmData: generateProp('ricConfirmData'),
  ricReference: generateProp('ricReference'),
  ricSendCartSms: generateProp('ricSendCartSms'),
  ricSummaryContactDetails: generateProp('ricSummaryContactDetails'),
  ricSendCart: generateProp('ricSendCart'),
  ricExit: generateProp('ricExit'),
  ricClerk: generateProp('ricClerk'),
  ricNoDataFound: generateProp('ricNoDataFound'),
  ricYourData: generateProp('ricYourData'),
  ricBusinessName: generateProp('ricBusinessName'),
  ricAddress: generateProp('ricAddress'),
  ricCity: generateProp('ricCity'),
  ricProvince: generateProp('ricProvince'),
  ricPostalCode: generateProp('ricPostalCode'),
  ricVillage: generateProp('ricVillage'),
  ricEmail: generateProp('ricEmail'),
  ricRecipient: generateProp('ricRecipient'),
  ricName: generateProp('ricName'),
  ricLastName: generateProp('ricLastName'),

};
