import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Matricole, MatricoleView } from '../interfaces/matricole';

@Injectable({
  providedIn: 'root'
})
export class FreshmenManagementService extends BaseHttpGeneric<Matricole, MatricoleView>{
  private matFormState: any;
  private checkboxStateKey = 'showOnlyActiveState';

  constructor() {
    super()
    this.controller = 'Matricole'
  }

  clearFormRoleState() {
    this.matFormState = null;
  }
  saveCheckboxState(state: string): void {
    localStorage.setItem(this.checkboxStateKey, JSON.stringify(state));
  }

  getCheckboxState(): 'active' | 'not-active' | 'both' {
    const state = localStorage.getItem(this.checkboxStateKey);
    return state ? JSON.parse(state) : 'both';
  }
}
