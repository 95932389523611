import { JsonService } from '@/_application/services/json.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';

@Component({
  selector: 'app-image-preview-upload',
  templateUrl: './image-preview-upload.component.html',
  styleUrls: ['./image-preview-upload.component.scss']
})
export class ImagePreviewUploadComponent implements OnInit {
  @Output() fileObject = new EventEmitter<File | null>();
  @Output() selectedFile = new EventEmitter<string>();

  @Input() filename: string = 'Por defecto';
  @Input() imageUrl = '';
  @Input() defaultImg = '';
  @Input() label: string | null = null;
  @Input() message = 'Field required';
  @Input() showError = false;
  public user: UserCurrent | null = null
  showDefaultImg: boolean = false;

  constructor(
    private readonly _authSvc: AuthenticationService,
    private readonly _jsonSvc: JsonService
  ) {
    this.user = this._authSvc.currentUserValue
  }

  ngOnInit(): void {
    this._jsonSvc.setShowDefaultImg(true);
    this._jsonSvc.showDefaultImg$.subscribe((res: boolean) => {
      this.showDefaultImg = res;
    });
  }

  handleChangeFile(evt: Event) {
    this._jsonSvc.setShowDefaultImg(false);
    const input = evt.target as HTMLInputElement;
    if (!input || !input.files) return;
    const { name, type } = input.files[0];
    this.fileObject.emit(input.files[0]);
    if (!this.validateType(type)) return;
    this.filename = name;
    this.getBase64(input.files[0]).then((elem: any) => {
      this.imageUrl = elem;
      const splitImg = elem.split(',')
      this.selectedFile.emit(splitImg[1]);
    });
  }
  
  handleImage(img: string) {
    if(img && img.length > 0) {
      return `data:;base64,${img}`
    }else {
      return this.handleDefaultImage(this.defaultImg) 
    }
  }

  handleDefaultImage(img: string) {
    let url = this._jsonSvc.getJsonImgUrl('../Azienda/Img/'+img)
    return url
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  validateType(type: string) {
    if (type.includes('image')) return true;
    return false;
  }

  handleDeleteImg() {
    this._jsonSvc.setShowDefaultImg(false);
    const photo = document.querySelector('#photo') as any;
    this.filename = 'Por defecto';
    this.imageUrl = '';
    this.fileObject.emit(null);
    this.selectedFile.emit(null);
    photo.value = null;
  }
}
