import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterColumnService {
  public columnsFilter: any[] = [];

  constructor() { }
}
