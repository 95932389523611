import { selectEntity } from '@/_application/config/genericEntity';
import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { NationsService } from '@modules/nations/services/nations.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-nazioni',
  templateUrl: './select-nazioni.component.html',
  styleUrls: ['./select-nazioni.component.scss']
})
export class SelectNazioniComponent extends HelperSelect {

  @Input() label = 'global.select.selectNazione'
  @Input() placeholder = selectEntity.selectAnOption

  constructor(private _patternSvc: NationsService) {
    super();
  }
  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp

      const options = data.map((elem): OptionsSelect => {
        const { codice, nazDescrizione } = elem
        return {
          label: nazDescrizione,
          value: codice
        }
      })
      this.rows = options
    })
  }

}
