import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component, Input } from '@angular/core';
import { FreshmenManagementService } from '@modules/freshmen-management/services/freshmen-management.service';
import { finalize } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-select-matricola',
  templateUrl: './select-matricola.component.html',
  styleUrls: ['./select-matricola.component.scss']
})
export class SelectMatricolaComponent extends HelperSelect {

  @Input() showError: boolean = false;
  @Input() control: any;

  deviceInfo = null;
  mobile = false
  constructor(
    private _patternSvc: FreshmenManagementService,
    private deviceService: DeviceDetectorService
  ) {
    super()
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    this.mobile = isMobile || isTablet
  }

  protected handleData(search: string, query?: any): void {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    },{
      filter: true
    }).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(resp => {
        const { data } = resp

        const options = data.map((elem): OptionsSelect => {
          const { codice, matCodice, matDescrizione } = elem
          return {
            label: matCodice + ' - ' + matDescrizione,
            value: this.codeView ? elem[this.codeView] : codice,
          }
        })

        this.rows = options
      })
  }

  openCameraOnMobile(event: Event) {
    event.preventDefault();
    if (this.mobile) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          
        })
        .catch(error => {
          console.error('Error al acceder a la cámara:', error);
          alert('Error al acceder a la cámara. Verifique que tenga permiso y que la cámara esté conectada.');
        });
    } else {
      alert('La función de cámara solo está disponible en dispositivos móviles.');
    }
  }

}
