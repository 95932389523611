import { SendData, typeMethod } from '@/_application/interfaces/report';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserCurrent } from '@modules/authentication/interfaces/user';
import { AuthenticationService } from '@modules/authentication/services/authentication.service';

@Component({
  selector: 'app-generic-header',
  templateUrl: './generic-header.component.html',
  styleUrls: ['./generic-header.component.scss']
})
export class GenericHeaderComponent implements OnInit {
  public initialValues = {
    search: ''
  };
  public form!: FormGroup;

  get f() {
    return this.form.controls;
  }
  public user: UserCurrent | null = null

   @Input() shouldClearSearch: boolean = false;
  @Output() request = new EventEmitter<string>();
  @Output() report: EventEmitter<SendData> = new EventEmitter<SendData>();
  @HostBinding('class') class = '';

  constructor(private fb: FormBuilder, private readonly _authSvc: AuthenticationService) {
    this.user = this._authSvc.currentUserValue
  }

    ngOnChanges(): void {
    if (this.shouldClearSearch) {
      this.form.patchValue({ search: '' });
    }
  }


  ngOnInit(): void {
    this.initForm();
    this.requestHttpSearch()
    this.class = this.user.current.role.canReport ? '' : 'justify-content-end'
  }

  private initForm() {
    const { search } = this.initialValues;
    this.form = this.fb.group({
      search: [search]
    });
  }

  requestHttpSearch() {
    this.f['search'].valueChanges.subscribe(elem => {
      this.request.emit(elem);
    })
  }
  requestHttp() {
    this.request.emit(this.f['search'].value);
  }

  requestReport(type: typeMethod) {
    this.report.emit({
      type,
      columns: ""
    }); 
  }
}
