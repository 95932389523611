<app-select-generic
                    [loading]="loading"
                    (searchValue)="handleData($event)"
                    [control]="control"
                    [showError]="showError"
                    [required]="required"
                    [label]="(label | translate) + ' *'"
                    [code]="code"
                    [options]="rows"
                    [disabled]="disabled"
                    (changeValue)="handleChange($event)"
                    [readonly]="readonly"></app-select-generic>