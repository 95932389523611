import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelectDropdown } from '@/_application/interfaces/select';
import { BranchDropdown } from '@modules/branches/interfaces/branches';
import { BranchesService } from '@modules/branches/services/branches.service';
import { selectEntity } from '@/_application/config/genericEntity';

@Component({
  selector: 'app-select-multicolumns2-filiali',
  templateUrl: './select-multicolumns2-filiali.component.html',
  styleUrls: ['./select-multicolumns2-filiali.component.scss']
})
export class SelectMulticolumns2FilialiComponent extends HelperSelect implements OnInit {

  @Output() selectedFiliali = new EventEmitter<OptionsSelectDropdown>()
  @Input() defaultValue: OptionsSelectDropdown | null = null

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  showTableFilter: boolean = false;
  data: BranchDropdown[];
  displayedColumns: string[];
  dataSource: MatTableDataSource<BranchDropdown>;
  selectedRow: BranchDropdown | null
  searchControl: FormControl = new FormControl<string>('');
  pubEntity = selectEntity

  constructor(
    private _patternSvc: BranchesService
  ) {
    super()
  }

  ngOnInit(): void {
    if (!this.disabled) this.handleData("")
    this.disabled = !this.user.current.role.canWrite
    this.searchControl.setValue(this.defaultValue ? this.defaultValue.label : '')

    this.displayedColumns = [
      'filRagioneSociale', 'filEmail', 'filCitta', 'filIndirizzo'
    ];

    this.searchControl.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged()
      )
      .subscribe(searchTerm => {
        const filter = searchTerm ? searchTerm : ''
        this.applyFilter(filter);
      });
  }

  applyFilter(searchTerm: string): void {
    searchTerm = searchTerm.trim().toLowerCase();
    this.dataSource.filter = searchTerm;
  }

  clearSearch() {
    this.searchControl.setValue('')
    this.selectedRow = null
    this.selectedFiliali.emit(null)
  }

  onRowClick(row: BranchDropdown): void {
    this.searchControl.setValue(row.filterName)
    this.selectedRow = row;
    this.showTableFilter = false;
    this.selectedFiliali.emit({
      label: '',
      value: row.codice
    })
  }

  handleClickOutsideDrop(): void {
    this.showTableFilter = false
  }

  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp

      const options = data.map((elem, index) => {
        const { filRagioneSociale, filEmail, filCitta, filIndirizzo, codice } = elem
        const filterName = (filRagioneSociale ? filRagioneSociale : '') + ' - ' + (filEmail ? filEmail : '') + ' - ' + ( filCitta ? filCitta : '' ) + ' - ' + ( filIndirizzo ? filIndirizzo : '' )

        return { filRagioneSociale, filEmail, filCitta, filIndirizzo, filterName, codice }
      })

      this.data = options
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      if (this.defaultValue != null){
        const found = this.dataSource.data.find(row => row.codice === this.defaultValue.value);
        if (found){
          this.onRowClick(found)
        }
      }
    })
  }

}
