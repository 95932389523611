<label for="">{{ label ? label : 'clienti.form.cliLogo' | translate }}</label>
<div class="wrapper">
    <div *ngIf="!imageBase64" class="show-image"></div>
    <img *ngIf="imageBase64" [src]="handleImage(imageBase64)" alt="" class="show-image" />
    <label *ngIf="user.current.role.canWrite">
      <i class="fa fa-pen icon-sm text-muted"></i>
      <input
        type="file"
        name="photo"
        accept="image/*"
        class="photo"
        (change)="handleChangeFile($event)"
        id="photo"
      />
    </label>
    <span class="btn-closed" (click)="handleDeleteImg()" *ngIf="imageBase64 && user.current.role.canWrite">
      <i class="fa fa-times icon-xs text-muted"></i>
    </span>
  <span class="form-text text-muted">{{ 'global.utils.fileType' | translate }}: png, jpg, jpeg.</span>
  </div>
  <div class="wrapper" *ngIf="showError">
    <div class="message font-normal text-base text-error">
      {{ message }}
    </div>
  </div>
