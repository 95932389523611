import { TableProps } from '@/_application/interfaces/generic-table';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterColumnService } from '@/_application/services/filter-column.service';
import { PfDropdown } from '@profabric/angular-components';
import { tableEntity } from '@/_application/config/genericEntity';

export const whiteList = ['cliId', 'desId', 'filId', "clienti", "matricole"]
@Component({
  selector: 'app-generic-row-header',
  templateUrl: './generic-row-header.component.html',
  styleUrls: ['./generic-row-header.component.scss']
})
export class GenericRowHeaderComponent implements OnInit {

  pubEntity = tableEntity

  ngOnInit(): void {
    const { module, view } = this.ConfigTable
    let label = ""
    if (whiteList.includes(this.fields)) {
      label = `${module}.${this.fields}`
    } else {
      if (module === "garanzie" && whiteList.includes(this.fields)) {
        label = `${module}.${this.fields}`
      } else {
        label = `${module}.${view}.${this.fields}`
      }
    }
    this.label = label
  }

  constructor(private readonly translateService: TranslateService, private filterService: FilterColumnService, private elementRef: ElementRef) {


  }
  public label = ""
  @Input() ConfigTable: TableProps
  @Input() filter = true
  @Input() column: string = "";
  @Input() fields: string = "";
  @Input() header: string = "";
  @Input() indexColumn: any = "";
  @Input() widthColumn: any = "";
  public active: boolean = false;
  public filterValue: string = ""
  @Output() sortEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() filterColumn: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dropdownTable') dropdown: PfDropdown;
  @ViewChild('filterInput') filterInput: any;


  sort() {
    this.sortEvent.emit(this.fields);
  }

  handleIconClick() {
    const widthScreen = window.innerWidth;
    const isFirstColumn = this.indexColumn === 0;
    if (isFirstColumn) {
      const width = parseFloat(this.widthColumn);
      if (widthScreen <= 1700 && width) {
        const width = parseFloat(this.widthColumn);
        const dropdownMenu = this.elementRef.nativeElement.querySelector('.menu-custom');

        dropdownMenu.style.position = 'absolute';
        dropdownMenu.style.width = '240px';

        if (width > 200) {
          dropdownMenu.style.left = '12px';
        } else {
          if (widthScreen > 1600 && widthScreen <= 1700) {
            dropdownMenu.style.left = '5px';
          }
          if (widthScreen > 1500 && widthScreen <= 1600) {
            dropdownMenu.style.left = '12px';
          }
          if (widthScreen > 1400 && widthScreen <= 1500) {
            dropdownMenu.style.left = '35px';
          }
          if (widthScreen > 1300 && widthScreen <= 1400) {
            dropdownMenu.style.left = '55px';
          }
          if (widthScreen > 1200 && widthScreen <= 1300) {
            dropdownMenu.style.left = '75px';
          }
          if (widthScreen > 200 && widthScreen <= 1200) {
            dropdownMenu.style.left = '75px';
          }
        }
      }
    }
    this.dropdown.isOpen = !this.dropdown.isOpen;

    if (this.dropdown.isOpen && isFirstColumn && widthScreen <= 1700) {
      const drop = this.elementRef.nativeElement.querySelector('.dropdown-menu');
      if (drop) {
        drop.classList.toggle('show');
        drop.style.border = 'none';
      }
    }
    if (this.dropdown.isOpen){
      setTimeout(() => {
        this.filterInput.nativeElement.focus();
        if (this.filterInput) {
        }
      }, 100);
    }
  }

  searchByFilterValue() {
    if (!this.filterValue) return
    this.active = true;
    this.dropdown.isOpen = false;

    const existingFieldIndex = this.filterService.columnsFilter.findIndex(filter => filter.field === this.fields);

    if (existingFieldIndex !== -1) {
      this.filterService.columnsFilter[existingFieldIndex].value = this.filterValue;
    } else {
      this.filterService.columnsFilter.push({
        field: this.fields,
        value: this.filterValue
      });
    }
    this.filterColumn.emit({ queryColumns: this.filterService.columnsFilter });
  }

  clearValue() {
    this.filterValue = ""
    this.filterService.columnsFilter = this.filterService.columnsFilter.filter(filter => filter.field !== this.fields);
    this.active = false;
    this.dropdown.isOpen = false;
    this.filterColumn.emit({ queryColumns: this.filterService.columnsFilter });
  }
}
