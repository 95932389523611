import { BehaviorSubject, Observable, finalize } from 'rxjs';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OptionsSelect } from '@/_application/interfaces/select';
import { HelperSelect } from '@/_application/helpers/helper-select';
import { RecipientsService } from '@modules/recipients/services/recipients.service';
import { selectEntity } from '@/_application/config/genericEntity';

@Component({
  selector: 'app-select-destinatari',
  templateUrl: './select-destinatari.component.html',
  styleUrls: ['./select-destinatari.component.scss']
})
export class SelectDestinatariComponent extends HelperSelect implements OnChanges {
  @Input() client: OptionsSelect | null = null
  pubEntity = selectEntity

  constructor(private _patternSvc: RecipientsService) {
    super()
  }



  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }, this.query).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(resp => {
        const { data } = resp

        const options = data.map((elem): OptionsSelect => {
          const { codice, desRagioneSociale } = elem
          return {
            label: desRagioneSociale,
            value: codice,
          }
        })

        this.rows = options
        console.log(this.rows);
        this.disabled = this.rows.length === 0;
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    const { client } = changes
    if (!client?.currentValue) {
      return
    }
    const value = client.currentValue.value
    const query = {
      cliId: value
    }
    this.query = query
    this.handleData('')
  }

}
