import { OptionsSelectNav, ValueNav } from '@/_application/interfaces/select';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-tabs',
  templateUrl: './multi-tabs.component.html',
  styleUrls: ['./multi-tabs.component.scss']
})
export class MultiTabsComponent {
  @HostBinding('class') class = 'card';
  @Input() tabs: OptionsSelectNav[] = []
  @Input() entity: any
  @Input() selectedNav: ValueNav = {
    opt:'',
  }
  @Input() marginLeftH3: string = '0px';
  @Output() selectedNavEmit = new EventEmitter<ValueNav>()

  handleSelectedNav(nav:ValueNav){
    this.selectedNavEmit.emit(nav);
  }
}
