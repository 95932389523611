import { GenericEntity } from "@/_application/config/genericEntity";
const rootEntity = 'pubblicazioni.form.';

const generateProp = (prop: string) => rootEntity + prop;

export const DocumentationEntity = {
    ...GenericEntity,
    add: 'documentation.add',
    edit: 'documentation.edit',
    messageDelete: 'documentation.messageDelete',
    messageAdd: 'documentation.messageAdd',
    messageEdit: 'documentation.messageEdit',
    messageExport: 'documentation.messageExport',
    fileName: 'documentation.fileName',
    route: '/documentation',
    form: {
        garDataPrimoAvviamento: 'Data Primo Avviamento',
        garModelloImbarcazione: 'Modello Imbarcazione',
        garPortoStazionamento: 'Porto Stazionamento',
        garNomeImbarcazione: 'Nome Imbarcazione',
        garCliente: 'Cliente *'
    },
    pubManuals: generateProp('pubManuals'),
    pubMaintenance: generateProp('pubMaintenance'),
    pubSpareParts: generateProp('pubSpareParts'),
    pubDocuments: generateProp('pubDocuments'),
    pubDescription: generateProp('pubDescription'),
    pubNotFound: generateProp('pubNotFound'),
    pubModel: generateProp('pubModel'),
    pubCode: generateProp('pubCode'),
    pubRevision: generateProp('pubRevision'),
    pubNote: generateProp('pubNote'),
    pubOpen: generateProp('pubOpen'),
    pubDownload: generateProp('pubDownload'),
}
export interface TypeServiceView {
    id: number;
    title: string;
    icon: string;
}
export const typeServiceDoc: TypeServiceView[] = [
    {
        id: 1,
        title: 'global.card-item.manuale',
        icon: 'fa-book'
    },
    {
        id: 2,
        title: 'global.card-item.gestioneManutenzioni',
        icon: 'fa-tools'
    },
    {
        id: 3,
        title: 'global.card-item.ricambi',
        icon: 'fa-cogs'
    }
]