import { selectEntity } from '@/_application/config/genericEntity';
import { dataDefaultSelect } from '@/_application/config/select';
import { HelperSelect } from '@/_application/helpers/helper-select';
import { OptionsSelect } from '@/_application/interfaces/select';
import { Component } from '@angular/core';
import { BranchesService } from '@modules/branches/services/branches.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-filiali',
  templateUrl: './select-filiali.component.html',
  styleUrls: ['./select-filiali.component.scss']
})
export class SelectFilialiComponent extends HelperSelect {
  pubEntity = selectEntity

  constructor(private _patternSvc: BranchesService) {
    super();
  }

  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }).pipe(finalize(() => {
      this.loading = false
    })).subscribe(resp => {
      const { data } = resp
      const options = data.map((elem): OptionsSelect => {
        const { codice, filRagioneSociale } = elem
        return {
          label: filRagioneSociale,
          value: codice
        }
      })
      this.rows = options
    })
  }



}
