<div class="form-group" [formGroup]="formGroup">
    <label [for]="nameInput" *ngIf="showLabel">{{ label | translate }} </label>
    <div class="input-group">
        <div class="custom-file">
            <input type="file" name="photo" 
                [formControlName]="nameInput" accept="image/*" class="custom-file-input"
                (change)="updateFileName($event)"
                >
            <label class="custom-file-label" >{{ selectedFileName }}</label>
        </div>
        <div class="input-group-append">
            <span class="input-group-text">Carica</span>
        </div>
    </div>
</div>