import {Injectable} from '@angular/core';
import {BaseHttpGeneric} from '@/_application/helpers/generic/base-htttp-generic';
import {
    BehaviorSubject,
    catchError,
    finalize,
    map,
    Observable,
    throwError
} from 'rxjs';
import {ResponseApi} from '@/_application/interfaces/response';
import {environment} from 'environments/environment';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TagliandiService extends BaseHttpGeneric<any, any> {
    constructor() {
        super();
        this.controller = 'Tagliandi';
    }
    private tagliandiFormState: any;
    public typeFrecuency: string;
    public disabled: boolean;
    public required: boolean;

    private pubId = new BehaviorSubject<string>('');
    public currentPubId$ = this.pubId.asObservable();
    private viewName: string;
    public type = new BehaviorSubject<string>('');
    public currentType$ = this.type.asObservable();
    public hours = new BehaviorSubject<string>('');
    public currentHours$ = this.hours.asObservable();
    public matCode = new BehaviorSubject<string>('');
    public currentMatCode$ = this.matCode.asObservable();

    setPubId(pubId: any) {
        this.pubId.next(pubId);
    }
    getPubId() {
        return this.pubId;
    }
    setView(viewName: any) {
        this.viewName = viewName;
    }
    getView() {
        return this.viewName;
    }
    setType(type: any) {
        this.type.next(type);
    }
    getType() {
        return this.type;
    }
    setHours(hours: any) {
        this.hours.next(hours);
    }
    getHours() {
        return this.hours;
    }
    setMatCode(matCode: any) {
        this.matCode.next(matCode);
    }
    getMatCode() {
        return this.matCode;
    }
    saveRoleFormState(formState: any) {
        this.tagliandiFormState = formState;
    }

    updateTypeFrecuency(type: string) {
        this.typeFrecuency = type;
    }

    getRoleFormState() {
        return this.tagliandiFormState;
    }

    clearFormRoleState() {
        this.tagliandiFormState = null;
    }
    getNextTagliandi(codice: string) {
        const url = this.handleUrl(false);
        return this.http.get<ResponseApi<any>>(
            `${url}/nextTagliandi/${codice}`
        );
    }
    getCurrentHour(codice: string) {
        const url = this.handleUrl(false);
        return this.http.get<ResponseApi<any>>(
            `${url}/GetLastRecordTagOreEffettuate/${codice}`
        );
    }
    getCalculaTagliandi(codice: string): Observable<string> {
        const url = this.handleUrl(false);
        return this.http
            .get(`${url}/calculaTagliandi/${codice}`, {responseType: 'text'})
            .pipe(catchError(this.handleError));
    }
    getMaintenanceStatus(codice: number, type: string): Observable<any> {
        const url = this.handleUrl(false);
        return this.http
            .get<ResponseApi<any>>(`${url}/Status/${codice}/type`)
            .pipe(map((response) => response.data));
    }

    setMaintenanceStatus(codice: number, searchRequest: any) {
        const url = this.handleUrl(false);
        return this.http.put<ResponseApi<any>>(
            `${url}/Status/${codice}`,
            searchRequest
        );
    }
    private handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
    handleUrl(auth = false) {
        const {server, local, production} = environment;
        if (production) {
            const {authenticate, apiUrl} = server;
            const url = auth ? authenticate : apiUrl;
            return `${url}/${this.controller}`;
        }
        const {authenticate, apiUrl} = local;
        const url = auth ? authenticate : apiUrl;
        return `${url}/${this.controller}`;
    }
}
