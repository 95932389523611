import { TableProps } from "@/_application/interfaces/generic-table";

export const ConfigTableDocumentazione: TableProps = {
    codigo: 'catID',
    route: 'documentation',
    module: 'documentation',
    view: 'form',
    rows: [
        {
            fields: 'catModello',
            header: 'Modello',
            width: '350px'
        },
        {
            fields: 'catCodice',
            header: 'Codice',
            width: '350px'
        },
         {
            fields: 'pubTipo',
            header: 'Tipo',
            width: '200px'
        },
        {
            fields: 'catData',
            header: 'Data',
            width: '200px'
        },
        {
            fields: 'catRevisione',
            header: 'Revisione',
            width: '200px'
        },
    ]
}