import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralInfo, SubMenu } from '../interfaces/wrapper';

@Injectable({
  providedIn: 'root'
})
export class WrapperService {
  //private fields
  private moduleSubject: BehaviorSubject<string>;
  private pathSubject: BehaviorSubject<string>;
  private subMenuSubject: BehaviorSubject<SubMenu[]>;
  private showTitle = new BehaviorSubject<boolean>(true);

  //public fields
  public module$: Observable<string>;
  public path$: Observable<string>;
  public subMenu$: Observable<SubMenu[]>;
  public showTitle$ = this.showTitle.asObservable();

  get currentSubMenuValue(): SubMenu[] {
    return this.subMenuSubject.value;
  }
  get currentModuleValue(): string {
    return this.moduleSubject.value;
  }
  constructor() {
    this.moduleSubject = new BehaviorSubject<string>('');
    this.pathSubject = new BehaviorSubject<string>('');
    this.subMenuSubject = new BehaviorSubject<SubMenu[]>([]);

    this.module$ = this.moduleSubject.asObservable();
    this.path$ = this.pathSubject.asObservable();
    this.subMenu$ = this.subMenuSubject.asObservable();
  }

  addGeneralInfo(data: GeneralInfo) {
    const { subMenu = [], title, path = "" } = data;
    this.moduleSubject.next(title);
    this.subMenuSubject.next(subMenu);
    this.pathSubject.next(path);
  }

  removeCurrent(){
    this.moduleSubject.next("");
    this.subMenuSubject.next([]);
    this.pathSubject.next("");
  }

  setShowTitle(value: boolean){
    this.showTitle.next(value);
  }

  getShowTitle(){
    return this.showTitle.value;
  }
}
