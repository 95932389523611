import { selectEntity } from '@/_application/config/genericEntity';
import { HelperSelect } from '@/_application/helpers/helper-select';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AgentsService } from '@modules/agents/services/agents.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-select-multicolumns-agenti',
  templateUrl: './select-multicolumns-agenti.component.html',
  styleUrls: ['./select-multicolumns-agenti.component.scss']
})
export class SelectMulticolumnsAgentiComponent extends HelperSelect implements OnInit {

  @Output() selectedAgent = new EventEmitter<any>()
  @Input() defaultValue: string = ''
  pubEntity = selectEntity

  selectedOption: any = '';
  headers: string[] = [
    'agenti.form.ageRagioneSociale', 'agenti.form.ageEmail', 'agenti.form.ageCitta', 'agenti.form.ageIndirizzo'
  ]

  dropdownOptions: any;

  constructor(
    private _patternSvc: AgentsService
  ) {
    super()
  }

  ngOnInit(): void {
    if (!this.disabled) this.handleData("")
    this.disabled = !this.user.current.role.canWrite
    this.selectedOption = this.defaultValue
  }

  getRowData(data) {
    if(data) {
      this.selectedAgent.emit({
        label: '',
        value: data.data[0].codice
      })
    }else{
      this.clear()
    }
  }

  clear() {
    this.selectedAgent.emit(null)
  }

  protected handleData(search: string) {
    this.loading = true
    this._patternSvc.getAllAsyncWhitQuery({
      ...this.default,
      search,
      loading: false
    }, this.query).pipe(finalize(() => {
      this.loading = false
    }))
      .subscribe(resp => {
        const { data } = resp

        const options = data.map((elem, index) => {
          const { ageRagioneSociale, ageEmail, ageCitta, ageIndirizzo } = elem

          return {
            header: index == 0,
            label:  (ageRagioneSociale ? ageRagioneSociale : '') + ' - ' + (ageEmail ? ageEmail : '') + ' - ' + ( ageCitta ? ageCitta : '' ) + ' - ' + ( ageIndirizzo ? ageIndirizzo : '' ),
            data: [ elem ]
          }
        })

        this.dropdownOptions = options
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    const { filiale } = changes
    if (!filiale?.currentValue) {
      this.query = null
      this.handleData('')
      return
    }
    const value = filiale.currentValue.value
    const query = {
      filId: value
    }
    this.query = query
    this.handleData('')
  }

}
