<div class="card">
  <div class="card-header">
    <app-generic-header (report)="handleReport($event)" class="row" [(ngModel)]="searchValue"
      [shouldClearSearch]="clearSearch" (request)="onSearch($event)">
    </app-generic-header>


    <div class="row mt-3" *ngIf="showSearchByMatricole">
      <div class="col-sm-9 p-0"> </div>
      <div class="col-sm-3 p-0">
        <form (ngSubmit)="onSearchMatricole()">
          <div class="input-group input-group-md">
            <input [(ngModel)]="matricolaSearchValue" name="matricolaSearch" type="text"
              class="form-control float-right" placeholder="{{'global.utils.ricercaDocPerMatricola' | translate}}">
            <div class="input-group-append">
              <button type="submit" class="btn btn-default">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </form>

        <!-- Reset button, aligned to have the same width as the search input -->
        <button class="btn btn-secondary btn-block mt-2" (click)="onResetFilters()">
          {{'global.utils.resetBtn' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div [ngClass]="{'card-body table-responsive p-0 position-relative': true, 'adv-bg-table': showModalFilter}">
    <table [ngClass]="{'mb':data.length === 1}" class="table table-bordered table-hover text-nowrap">
      <thead>
        <tr>
          <th class="position-relative" [ngClass]="{'right':ConfigTable.rows.length === i +1}" role="button"
            [style.width]="row?.width" [style.min-width]="row?.width" [style.max-width]="row?.width"
            *ngFor="let row of ConfigTable.rows; index as i">
            <app-generic-row-header [ConfigTable]="ConfigTable" [column]="column" (sortEvent)="sort($event)"
              [header]="row.header" [fields]="row.fields" [filter]="filter" (filterColumn)="filterByColumn($event)"
              [indexColumn]="i" [widthColumn]="row?.width"></app-generic-row-header>
          </th>
          <th *ngIf="readOnly">
            {{ pubEntity.tableActions | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr role="button" (click)="goEditModel(item[ConfigTable.codigo], ConfigTable.params)" *ngFor="let item of data">
          <td [style.width]="field?.width" [style.min-width]="field?.width" [style.max-width]="field?.width"
            *ngFor="let field of ConfigTable.rows">

            <ng-container *ngIf="field.fields === 'pubTipo'; else otherFields">
              <i [ngClass]="getPubTipoIcon(item[field.fields]).icon"
                [tooltip]="getPubTipoIcon(item[field.fields]).tooltipKey | translate" placement="right"
                container="body"></i>
            </ng-container>


            <ng-template #otherFields>
              <span *ngIf="!field.bool">
                {{ handleLabel(item[field.fields]) }}
              </span>
              <div class="icheck-primary d-inline" *ngIf="field.bool">
                <input type="checkbox" [checked]="item[field.fields]" disabled>
                <label></label>
              </div>
            </ng-template>
          </td>
          <td *ngIf="readOnly">
            <button *ngIf="!item.linID || item.langId !== '1'" [title]="pubEntity.tableDelete | translate" type="button"
              (click)="handleDelete(item)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
        <tr *ngIf="data.length === 0">
          <td [colSpan]="ConfigTable.rows.length" class="empty-column">
            <span>{{ pubEntity.tableNoItemsToShow | translate }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer clearfix" *ngIf="data.length > 0">
    <app-generic-pagination [meta]="meta" (page)="pageChanged($event)"
      class="dataTables_paginate paging_simple_numbers row">
    </app-generic-pagination>
  </div>
</div>
<div [style.top]="top" [style.left]="left" class="adv-table-container" [hidden]="!(showModalFilter)">
  <h4><b>{{ pubEntity.tableSearchBy | translate }}:</b> {{selectedHeader}}</h4>
  <input type="text" autofocus [(ngModel)]="filterValue" (keyup.enter)="searchByFilterValue()" class="form-control"
    [placeholder]="(pubEntity.tableSearch | translate)+'...'">
  <button type="submit" (click)="searchByFilterValue()" class="btn btn-dark mt-2">
    {{ pubEntity.tableFilter | translate }}
  </button>
</div>