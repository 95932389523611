import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecipientsService extends BaseHttpGeneric<any, any> {
  private recipientFormState: any;
  constructor() {
    super()
    this.controller = 'Destinatari'

  }

  saveRecipientFormState(formState: any) {
    this.recipientFormState = formState;
  }

  getRecipientFormState() {
    return this.recipientFormState;
  }

  clearFormRecipientState() {
    this.recipientFormState = null;
  }
}
