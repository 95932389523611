export const TypeUSerUrl = {
    "clienti": 0,
    "destinatari": 1,
    "agenti": 2,
    "filiali": 3,
    "officine-autorizzate": 4,
    "armatori": 5,
    "distributori": 6,
    "installatori": 7,
    "fornitori": 8,
}