import { BaseHttpGeneric } from '@/_application/helpers/generic/base-htttp-generic';
import { Injectable } from '@angular/core';
import { Agency, AgencyView } from '../interface/agency';

@Injectable({
  providedIn: 'root'
})
export class AgencyService extends BaseHttpGeneric<Agency, AgencyView> {

  constructor() {
    super()
    this.controller = 'Azienda'
  }
}
